import React from "react";
import OpenMenu from "../assets/menu_open.png";
import Guest from "../assets/sideGuestBlack.svg";
import Analytics from "../assets/sideAnalytisBlack.svg";
import Website from "../assets/sideWebBlack.svg";
import Report from "../assets/sideReportBlack.svg";
import HelpSupport from "../assets/sideHelpBlack.svg";
import GuestOrng from "../assets/sideGuestOrng.svg";
import AnalyticsOrng from "../assets/sideAnalyticsOrng.svg";
import WebsiteOrng from "../assets/sideWebOrng.svg";
import ReportOrng from "../assets/sideReportOrng.svg";
import HelpSupportOrng from "../assets/sideHelpOrng.svg";
import NewLogo from "../assets/logo_shopup.png";
import StaffOrng from "../assets/sideStaffOrng.svg";
import dropArrowwOrange from "../assets/dropArrowOrange.svg";
import dropArroww from "../assets/dropArrow.svg";
import Staff from "../assets/sideStaffBlack.svg";
import Dashboard from "../assets/sideDashblack.svg";
import BusinessBlack from "../assets/sideBlistblack.svg";
import Business from "../assets/sideBlist.svg";
import Product from "../assets/sideProBlack.svg";
import ProductBlue from "../assets/sideProBLue.svg";
import Pack from "../assets/sidePackBlack.svg";
import PackBlue from "../assets/sidePackBlue.svg";
import ContactBlue from "../assets/sideContactBlue.svg";
import Contact from "../assets/sideContactBlack.svg";

import "./Css/SideBar.css";
import { Link, useLocation } from "react-router-dom";
function SideBar({ isSidebarOpen, toggleSidebar }) {
  const location = useLocation();

  return (
    <div className={`sidebar ${isSidebarOpen ? "OpneSidebar" : ""}`}>
      <div className="sidebar_container">
        <div className="sideHeadLogo">
          <a className="navbar-brand" href="#">
            <div className="headerLogo">
              <div className="headerLogo_contaienr">
                <img src={NewLogo} alt="Shopup Admin" />
              </div>
            </div>
          </a>
        </div>
        <div
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          htmlFor="top"
          title={`Hide`}
          className="menuOpenClose"
          onClick={toggleSidebar}
          style={{
            paddingLeft: "0",
            marginLeft: "2rem",
            justifyContent: "flex-start",
          }}
        >
          <img
            style={{ transform: " rotate(0deg)" }}
            src={OpenMenu}
            alt="Shopup Admin"
          />
        </div>
        <div className="sidebar_list">
          <ul>
            <Link to="/Dashboard">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Dashboard" ? "activeSideItem" : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Dashboard"
                        ? AnalyticsOrng
                        : Dashboard
                    }
                    alt="Shopup Admin"
                  />
                  <p>Dashboard</p>
                </div>
              </li>
            </Link>
            <li className="dropdown sidebarDropdown staff_mngmt">
              <div
                data-bs-toggle="dropdown"
                aria-expanded="false"
                className={`sidebar_list_item dropdown-toggle ${
                  location.pathname === "/View_Staff" ||
                  location.pathname === "/Add_Staff" ||
                  location.pathname === "/View_Staff_Rights" ||
                  location.pathname === "/View_Sub_Rights" ||
                  location.pathname === "/View_Inside_Page_Rights" ||
                  location.pathname === "/Create_Staff_Rights" ||
                  location.pathname === "/Edit_Staff_Rights" ||
                  location.pathname === "/ViewSearchMaster" ||
                  location.pathname === "/Add_SearchMaster" ||
                  location.pathname === "/Edit_Sub_Rights" ||
                  location.pathname === "/Create_Inside_Page_Rights" ||
                  location.pathname === "/ViewDesignation" ||
                  location.pathname.includes("/edit_staff") ||
                  location.pathname.includes("/Edit_SearchMaster") ||
                  location.pathname.includes("/Edit_Designation")
                    ? "activeSideItem"
                    : " "
                }`}
              >
                <img
                  src={
                    location.pathname === "/View_Staff" ||
                    location.pathname === "/Add_Staff" ||
                    location.pathname === "/View_Staff_Rights" ||
                    location.pathname === "/View_Sub_Rights" ||
                    location.pathname === "/View_Inside_Page_Rights" ||
                    location.pathname === "/ViewSearchMaster" ||
                    location.pathname === "/Add_SearchMaster" ||
                    location.pathname === "/Create_Staff_Rights" ||
                    location.pathname === "/Edit_Staff_Rights" ||
                    location.pathname === "/Edit_Sub_Rights" ||
                    location.pathname === "/Create_Inside_Page_Rights" ||
                    location.pathname === "/ViewDesignation" ||
                    location.pathname.includes("/edit_staff") ||
                    location.pathname.includes("/Edit_SearchMaster") ||
                    location.pathname.includes("/Edit_Designation")
                      ? StaffOrng
                      : Staff
                  }
                  alt="Barley's Dashboard"
                />
                <p>Staff</p>
                <div className="droparrow">
                  <img
                    src={
                      location.pathname === "/View_Staff" ||
                      location.pathname === "/Add_Staff" ||
                      location.pathname === "/View_Staff_Rights" ||
                      location.pathname === "/View_Sub_Rights" ||
                      location.pathname === "/View_Inside_Page_Rights" ||
                      location.pathname === "/ViewSearchMaster" ||
                      location.pathname === "/Add_SearchMaster" ||
                      location.pathname === "/Create_Staff_Rights" ||
                      location.pathname === "/Edit_Staff_Rights" ||
                      location.pathname === "/Edit_Sub_Rights" ||
                      location.pathname === "/Create_Inside_Page_Rights" ||
                      location.pathname === "/ViewDesignation" ||
                      location.pathname.includes("/edit_staff") ||
                      location.pathname.includes("/Edit_SearchMaster") ||
                      location.pathname.includes("/Edit_Designation")
                        ? dropArrowwOrange
                        : dropArroww
                    }
                    alt="img"
                  ></img>
                </div>
              </div>
              <ul className="dropdown-menu">
                <li>
                  <Link className="dropdown-item" to="/View_Staff">
                    View Staff
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/ViewDesignation">
                    Designations
                  </Link>
                </li>
              </ul>
            </li>
            <Link to="/view_business_owner">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_business_owner"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_business_owner"
                        ? GuestOrng
                        : Guest
                    }
                    alt="Shopup Admin"
                  />
                  <p>Business Owner</p>
                </div>
              </li>
            </Link>
            <Link to="/view_business_list">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_business_list" ||
                    location.pathname === "/Add_Business" ||
                    location.pathname.includes("/Add_paymant")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_business_list" ||
                      location.pathname === "/Add_Business" ||
                      location.pathname.includes("/Add_paymant")
                        ? Business
                        : BusinessBlack
                    }
                    alt="Shopup Admin"
                  />
                  <p>Business List</p>
                </div>
              </li>
            </Link>
            <Link to="/View_Product_list">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/View_Product_list" ||
                    location.pathname === "/Add_Category" ||
                    location.pathname === "/add_product" ||
                    location.pathname === "/View_Category_list" ||
                    location.pathname.includes("/edit_category") ||
                    location.pathname.includes("/edit_product_images") ||
                    location.pathname.includes("/edit_product")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/View_Product_list" ||
                      location.pathname === "/Add_Category" ||
                      location.pathname === "/add_product" ||
                      location.pathname === "/View_Category_list" ||
                      location.pathname.includes("/edit_category") ||
                      location.pathname.includes("/edit_product_images") ||
                      location.pathname.includes("/edit_product")
                        ? ProductBlue
                        : Product
                    }
                    alt="Shopup Admin"
                  />
                  <p>Product Master</p>
                </div>
              </li>
            </Link>
            <Link to="/view_Package_list">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_Package_list" ||
                    location.pathname === "/add_Package" ||
                    location.pathname.includes("/edit_Package")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_Package_list" ||
                      location.pathname === "/add_Package" ||
                      location.pathname.includes("/edit_Package")
                        ? PackBlue
                        : Pack
                    }
                    alt="Shopup Admin"
                  />
                  <p>Package Master</p>
                </div>
              </li>
            </Link>
            <Link to="/view_contact_us">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_contact_us"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_contact_us"
                        ? ContactBlue
                        : Contact
                    }
                    alt="Shopup Admin"
                  />
                  <p>Enquiry</p>
                </div>
              </li>
            </Link>
            <Link to="/view_request_us">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_request_us"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_request_us"
                        ? ContactBlue
                        : Contact
                    }
                    alt="Shopup Admin"
                  />
                  <p>Request Form</p>
                </div>
              </li>
            </Link>
            <Link to="/view_reports">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/view_reports"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/view_reports"
                        ? ReportOrng
                        : Report
                    }
                    alt="Shopup Admin"
                  />
                  <p>Reports</p>
                </div>
              </li>
            </Link>

            <Link to="/Manuals">
              <li>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Help_And_Support" ||
                    location.pathname === "/FAQs" ||
                    location.pathname === "/Manuals" ||
                    location.pathname === "/View_Faq" ||
                    location.pathname === "/Add_Faq" ||
                    location.pathname === "/View_KB" ||
                    location.pathname === "/Add_KB" ||
                    location.pathname.includes("/Edit_Faq") ||
                    location.pathname.includes("/Edit_KB")
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Help_And_Support" ||
                      location.pathname === "/FAQs" ||
                      location.pathname === "/Manuals" ||
                      location.pathname === "/View_Faq" ||
                      location.pathname === "/Add_Faq" ||
                      location.pathname === "/View_KB" ||
                      location.pathname === "/Add_KB" ||
                      location.pathname.includes("/Edit_Faq") ||
                      location.pathname.includes("/Edit_KB")
                        ? HelpSupportOrng
                        : HelpSupport
                    }
                    alt="Shopup Admin"
                  />
                  <p>Help and Support</p>
                </div>
              </li>
            </Link>

            <Link to="/Website_Management">
              <li className={`website_mngmt`}>
                <div
                  className={`sidebar_list_item ${
                    location.pathname === "/Website_Management"
                      ? "activeSideItem"
                      : " "
                  }`}
                >
                  <img
                    src={
                      location.pathname === "/Website_Management"
                        ? WebsiteOrng
                        : Website
                    }
                    alt="Shopup Admin"
                  />
                  <p>Website Mngmt</p>
                </div>
              </li>
            </Link>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
