import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import { Modal, Button } from "react-bootstrap";
import {
  server_post_data,
  get_business_payment_list,
  add_business_payment_admin,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  handleIaphabetnumberChange,
} from "../CommonJquery/CommonJquery.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleLinkClick,
  empty_form,
  handleSuccess,
} from "../CommonJquery/CommonJquery.js";
import { useParams } from "react-router-dom";

function Add_Payment() {
  const { id } = useParams();
  if (id === undefined) {
    id = 0;
  }
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [SelectedData, setSelectedData] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0); // State to hold the selected value
  const [selectedValue1, setSelectedValue1] = useState("Online"); // State to hold the selected value
  const [showModal, setShowModal] = useState(false);

  const [Package, setSetPackage] = useState([]);

  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };
  const handleSelect1 = (event) => {
    setSelectedValue1(event.target.value); // Update the selected value when an option is selected
  };

  useEffect(() => {
    let call_id = id;
    master_data_get(call_id);
  }, []);

  const master_data_get = async (call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("call_id", call_id);
    await server_post_data(get_business_payment_list, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setSetPackage(Response.data.message.packages);
          setfilteredData(Response.data.message.package_list);
          if (Response.data.message.packages.length > 0) {
            setSelectedValue(Response.data.message.packages[0].primary_id);
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("call_id", id);
      fd_from.append("type", selectedValue1);
      fd_from.append("Package_id", selectedValue);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get(id);
            handleSuccess(Response.data.message);
            empty_form(form_data);
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };
  const openModal = (guestName) => {
    setSelectedData(guestName);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };
  console.log(SelectedData);
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p onClick={() => handleLinkClick("view_business_list")}>
                  <p>
                    View Business
                    <img src={GreyArrow} alt="Shopup Admin" />
                  </p>
                </p>
                <p>Add Payment</p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="col-xl-12 col-lg-12 ">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0"></div>
                          <div className={`row `}>
                            <div className="col-lg-3 col-md-4">
                              <label
                                className="labelPointer opacity-0"
                                htmlFor="selectFilter"
                              >
                                Select Package
                              </label>
                              <select
                                id="selectFilter"
                                className={` form-control trio_mandatory`}
                                style={{ height: "40px", appearance: "auto" }}
                                onChange={handleSelect}
                              >
                                <option value={""}>Package</option>
                                {Package.length > 0 &&
                                  Package.map((option) => (
                                    <option
                                      key={option.primary_id}
                                      value={option.primary_id}
                                    >
                                      {option.package_name}
                                    </option>
                                  ))}
                              </select>
                              <span className="condition_error mt-1"></span>
                            </div>
                            <div className="col-lg-3 col-md-4 ">
                              <label
                                className="labelPointer opacity-0"
                                htmlFor="selectFilter"
                              >
                                Payment Type
                              </label>
                              <div className="inputDiv2 trio_mandatory bg-white">
                                <select
                                  id="selectFilter"
                                  onChange={handleSelect1}
                                >
                                  <option value={"Online"}>Online</option>
                                  <option value={"Cash"}>Cash</option>
                                </select>
                              </div>
                            </div>
                            {selectedValue1 == "Online" ? (
                              <div className={`col-lg-3 col-md-4`}>
                                <div className="inpContainer mb-0 ">
                                  <label className="no_prsnl_id">
                                    Transaction Id
                                    <span className="colorred">*</span>
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      id="transaction_id"
                                      name="transaction_id"
                                      className={`height40px input_field_customPadding trio_mandatory form-control`}
                                      placeholder="Transaction Id"
                                      onInput={handleIaphabetnumberChange}
                                    />
                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className={`row `}>
                              <div className={`col-md-12`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">Remark</label>
                                  <div>
                                    <textarea
                                      type="text"
                                      id="remark"
                                      name="remark"
                                      minLength={4}
                                      maxLength={100}
                                      className={`    input_field_customPadding form-control `}
                                      placeholder="Remark"
                                      onInput={handleIaphabetnumberChange}
                                    />
                                    {/* <span className="condition_error"></span> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="createRightBtnDiv">
                            <button
                              className="btn btn-primary Create_Right_Btn btnSave"
                              type="button"
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  "createRightsForm",
                                  add_business_payment_admin
                                )
                              }
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <span>Package Name</span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Package Price</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">SMS </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Days</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Start Date</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">End Date</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Payment Type</div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="th1 tabledata">
                                    <div className="guest_incenter borderLeftRadius">
                                      {option.package_name}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.package_price}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.sms_credit}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.total_days}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.subscription_start_date}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.subscription_end_date}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.payment_type}
                                    </div>
                                  </td>
                                  <td className="th2 tabledata">
                                    <div className="guest_incenterActions borderRightRadius">
                                      <button
                                        type="button"
                                        className={`markVip Mark_Vip vipMarke`}
                                        onClick={() => openModal(option)}
                                      >
                                        <p>Details</p>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="text_align_center border_1px"
                                >
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center"></div>
          <div className="width100per">
            <div>
              <p>Transaction Id</p>
              <p>{SelectedData.payment_transaction_id}</p>
            </div>
            <div>
              <p>Remark</p>
              <p>{SelectedData.payment_remark}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="logoutYesBtn" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Add_Payment;
