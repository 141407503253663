import React, { useState } from "react";
import Header from "./Header";
import Faqs from "./Faqs";
import UserManual from "./UserManual";
import { Link } from "react-router-dom";
import Help from "./Help";
function Manuals() {
  const [calendarTab, setCalendarTab] = useState(false);
  const [historyTab, setHistoryTab] = useState(false);
  const [SupportTab, setSupportTab] = useState(true);

  const toggleTabs = (Tab) => {
    setCalendarTab(Tab === "Calender");
    setHistoryTab(Tab === "History");
    setSupportTab(Tab === "Support");
  };

  return (
    <div className="dashboard">
      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head mb-3">
              <div className="calendertabs">
                <div className="calendertabs_container">
                  <div
                    className={`calendertabsBtn width150px ${
                      SupportTab ? "activeCalendarTab" : ""
                    }`}
                    onClick={() => toggleTabs("Support")}
                  >
                    <p>Support</p>
                  </div>
                  <div
                    className={`calendertabsBtn width150px ${
                      calendarTab ? "activeCalendarTab" : ""
                    }`}
                    onClick={() => toggleTabs("Calender")}
                  >
                    <p>FAQs</p>
                  </div>
                  <div
                    className={`calendertabsBtn width150px ${
                      historyTab ? "activeCalendarTab" : ""
                    }`}
                    onClick={() => toggleTabs("History")}
                  >
                    <p>Knowledge Base</p>
                  </div>
                </div>
              </div>
              <div className="tabsBtn">
                {calendarTab && (
                  <Link to="/View_Faq">
                    <button
                      type="button"
                      className="btnAddStaff darkBg add_FAQ"
                    >
                      Add FAQ
                    </button>
                  </Link>
                )}
                {historyTab && (
                  <Link to="/View_KB">
                    <button
                      type="button"
                      className="btnAddStaff darkBg add_User_Manual"
                    >
                      Add User Manual
                    </button>
                  </Link>
                )}
              </div>
            </div>

            <div className="page_body">
              {calendarTab && <Faqs />}
              {historyTab && <UserManual />}
              {SupportTab && <Help />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Manuals;
