import React, { useState, useEffect } from "react";
import "./Css/StaffRights.css";
import "./Css/AddStaff.css";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FolderImg from "../assets/Folder-pana.png";
import {
  CreateStaffRightText,
  EditStaffPage,
} from "../CommonJquery/WebsiteText.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
  empty_form,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleLinkClick,
  handleAphabetsChange,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  add_product_webapp_master,
  get_all_cate_product,
} from "../ServiceConnection/serviceconnection.js";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import Select from "react-select";
function Add_Product() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [groupedOptions, setgroupedOptions] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);

  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size <= 500 * 1024) {
        // 500KB in bytes
        handleError("File size is below the minimum limit (500KB).");
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        // 2MB in bytes
        handleError("File size exceeds the maximum limit (2MB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      handleError("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("product_id", 0);
      fd_from.append("total_image_loop", 3);
      fd_from.append("image_product", 1);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/View_Product_list");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    const url = currentUrl;
    const parts = url.split("/");
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    fd.append("call_id", call_id);
    fd.append("flag", 1);
    await server_post_data(get_all_cate_product, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setgroupedOptions(Response.data.message.data_categoryInformation);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="pageNameDiv">
                <p onClick={() => handleLinkClick("View_Product_list")}>
                  <p>
                    View Product
                    <img src={GreyArrow} alt="Shopup Admin" />
                  </p>
                </p>

                <p>
                  <p>
                    {" "}
                    {location.pathname.includes("/edit_product")
                      ? EditStaffPage.save_text
                      : CreateStaffRightText.Create_text}{" "}
                    Product
                  </p>
                </p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="col-xl-12 col-lg-12 ">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0">
                            <div className={`col-md-3 `}>
                              <div className="inpContainer">
                                <div className="display_grid ">
                                  <label className="no_prsnl_id">
                                    Category Name
                                    <span className="colorred">*</span>
                                  </label>
                                  <Select
                                    options={groupedOptions}
                                    name="select_category_id"
                                    formatGroupLabel={formatGroupLabel}
                                    onop
                                  />

                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-3`}>
                              <div className="inpContainer">
                                <label className="no_prsnl_id">
                                  Product Name
                                  <span className="colorred">*</span>
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="product_name"
                                    name="product_name"
                                    minLength={4}
                                    maxLength={100}
                                    className={`  trio_mandatory   Padding form-control`}
                                    placeholder="Product Name"
                                    onInput={handleIaphabetnumberChange}
                                  />

                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-3`}>
                              <div className="inpContainer">
                                <label className="no_prsnl_id">
                                  Product Qty
                                  <span className="colorred">*</span>
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="product_qty"
                                    name="product_qty"
                                    maxLength={10}
                                    className={`  trio_mandatory   Padding form-control`}
                                    // className={`  trio_mandatory  input_field_custom1 Padding form-control`}
                                    placeholder="Product Qty"
                                    onInput={handleNumbersChange}
                                  />

                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-3`}>
                              <div className="inpContainer">
                                <label className="no_prsnl_id">
                                  Qty Unit in (GM,KG,ML)
                                  <span className="colorred">*</span>
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="product_qty_unit"
                                    name="product_qty_unit"
                                    maxLength={10}
                                    className={`  trio_mandatory   Padding form-control`}
                                    placeholder="Product Qty Unit Name"
                                    onInput={handleAphabetsChange}
                                  />

                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-6`}>
                              <div className="inpContainer">
                                <label className="no_prsnl_id">
                                  Product Details
                                  <span className="colorred">*</span>
                                </label>
                                <div>
                                  <textarea
                                    id="product_detail"
                                    name="product_detail"
                                    maxLength={300}
                                    minLength={4}
                                    className={`  trio_mandatory   Padding form-control`}
                                    placeholder="Product Details"
                                    onInput={handleIaphabetnumberChange}
                                  ></textarea>
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className={`col-md-6`}>
                              <div className="inpContainer">
                                <label className="no_prsnl_id">
                                  Product Highlight
                                </label>
                                <div>
                                  <textarea
                                    id="product_highlight"
                                    name="product_highlight"
                                    maxLength={300}
                                    minLength={4}
                                    className={`     Padding form-control`}
                                    placeholder="Product Highlight"
                                    onInput={handleIaphabetnumberChange}
                                  ></textarea>

                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                            <div className={`row `}>
                              <div className={`col-md-3`}>
                                <div className="inpContainer flexColum">
                                  <label className="no_prsnl_id">
                                    Main Product Image
                                    <span className="colorred">*</span>
                                    <br></br>
                                    <span className="colorred imageSizeText">
                                      Size 600*600 max (2 MB)
                                    </span>
                                  </label>
                                  <div className="w-100">
                                    <div className="w-100 text-center">
                                      {dynaicimage &&
                                      dynaicimage.doctor_image0_show ? (
                                        <img
                                          src={dynaicimage.doctor_image0_show}
                                          onError={(e) =>
                                            (e.target.src = FolderImg)
                                          }
                                          alt="Shopup Admin"
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={FolderImg}
                                          alt="Shopup Admin"
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                          }}
                                        />
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      name="doctor_image0"
                                      onChange={handleFileChangedynamic(
                                        "doctor_image0"
                                      )}
                                      className={`${
                                        dynaicimage &&
                                        dynaicimage.doctor_image0_show
                                          ? ""
                                          : "trio_mandatory"
                                      } form-control`}
                                      accept=".jpg,.jpeg,.png"
                                      hidden
                                      id="imgInput"
                                    />
                                    <label
                                      className="imgInpBtn m-auto"
                                      htmlFor="imgInput"
                                    >
                                      {dynaicimage &&
                                      dynaicimage.doctor_image0_show
                                        ? "Change Image"
                                        : " Choose Image"}
                                    </label>

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <div className="inpContainer flexColum">
                                  <label className="no_prsnl_id">
                                    Product Image 2
                                    <span className="colorred">*</span>
                                    <br></br>
                                    <span className="colorred imageSizeText">
                                      Size 600*600 max (2 MB)
                                    </span>
                                  </label>
                                  <div className="w-100">
                                    <div className="w-100 text-center">
                                      {dynaicimage &&
                                      dynaicimage.doctor_image1_show ? (
                                        <img
                                          src={dynaicimage.doctor_image1_show}
                                          onError={(e) =>
                                            (e.target.src = FolderImg)
                                          }
                                          alt="Shopup Admin"
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            marginBottom: "0.5rem",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={FolderImg}
                                          alt="Shopup Admin"
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            marginBottom: "0.5rem",
                                          }}
                                        />
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      name="doctor_image1"
                                      onChange={handleFileChangedynamic(
                                        "doctor_image1"
                                      )}
                                      className={`${
                                        dynaicimage &&
                                        dynaicimage.doctor_image1_show
                                          ? ""
                                          : "trio_mandatory"
                                      } form-control`}
                                      accept=".jpg,.jpeg,.png"
                                      hidden
                                      id="imgInput2"
                                    />
                                    <label
                                      className="imgInpBtn m-auto"
                                      htmlFor="imgInput2"
                                    >
                                      {dynaicimage &&
                                      dynaicimage.doctor_image1_show
                                        ? "Change Image"
                                        : " Choose Image"}
                                    </label>

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <div className="inpContainer flexColum">
                                  <label className="no_prsnl_id">
                                    Product Image 3
                                    <span className="colorred">*</span>
                                    <br></br>
                                    <span className="colorred imageSizeText">
                                      Size 600*600 max (2 MB)
                                    </span>
                                  </label>
                                  <div>
                                    <div className="w-100 text-center">
                                      {dynaicimage &&
                                      dynaicimage.doctor_image2_show ? (
                                        <img
                                          src={dynaicimage.doctor_image2_show}
                                          onError={(e) =>
                                            (e.target.src = FolderImg)
                                          }
                                          alt="Shopup Admin"
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            marginBottom: "0.5rem",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={FolderImg}
                                          alt="Shopup Admin"
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            marginBottom: "0.5rem",
                                          }}
                                        />
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      name="doctor_image2"
                                      onChange={handleFileChangedynamic(
                                        "doctor_image2"
                                      )}
                                      className={`${
                                        dynaicimage &&
                                        dynaicimage.doctor_image2_show
                                          ? ""
                                          : "trio_mandatory"
                                      } form-control`}
                                      accept=".jpg,.jpeg,.png"
                                      hidden
                                      id="imgInput3"
                                    />
                                    <label
                                      className="imgInpBtn m-auto"
                                      htmlFor="imgInput3"
                                    >
                                      {dynaicimage
                                        ? "Change Image"
                                        : " Choose Image"}
                                    </label>

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <div className="inpContainer flexColum">
                                  <label className="no_prsnl_id">
                                    Product Image 4
                                    <span className="colorred">*</span>
                                    <br></br>
                                    <span className="colorred imageSizeText">
                                      Size 600*600 max (2 MB)
                                    </span>
                                  </label>
                                  <div className="w-100">
                                    <div className="w-100 text-center">
                                      {dynaicimage &&
                                      dynaicimage.doctor_image3_show ? (
                                        <img
                                          src={dynaicimage.doctor_image3_show}
                                          onError={(e) =>
                                            (e.target.src = FolderImg)
                                          }
                                          alt="Shopup Admin"
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            marginBottom: "0.5rem",
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={FolderImg}
                                          alt="Shopup Admin"
                                          style={{
                                            width: "150px",
                                            height: "150px",
                                            marginBottom: "0.5rem",
                                          }}
                                        />
                                      )}
                                    </div>
                                    <input
                                      type="file"
                                      name="doctor_image3"
                                      onChange={handleFileChangedynamic(
                                        "doctor_image3"
                                      )}
                                      className={`${
                                        dynaicimage &&
                                        dynaicimage.doctor_image3_show
                                          ? ""
                                          : "trio_mandatory"
                                      } form-control`}
                                      accept=".jpg,.jpeg,.png"
                                      hidden
                                      id="imgInput4"
                                    />
                                    <label
                                      className="imgInpBtn m-auto"
                                      htmlFor="imgInput4"
                                    >
                                      {dynaicimage &&
                                      dynaicimage.doctor_image3_show
                                        ? "Change Image"
                                        : " Choose Image"}
                                    </label>

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="createRightBtnDiv">
                            <button
                              className="btn btn-primary Create_Right_Btn btnSave"
                              type="button"
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  "createRightsForm",
                                  add_product_webapp_master
                                )
                              }
                            >
                              {location.pathname.includes("/edit_product")
                                ? EditStaffPage.save_text
                                : CreateStaffRightText.Create_text}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_Product;
