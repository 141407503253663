import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import "./Css/AddStaff.css";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import { Link } from "react-router-dom";
import GuestIcon from "../assets/guestIcon.png";
import { Modal, Button } from "react-bootstrap";
import { ProductManagementPageText } from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  get_all_admin_category,
  update_category_master_active_status,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  computeplussevendays,
} from "../CommonJquery/CommonJquery.js";
function CategoryMngmt() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [flagData, setflagData] = useState("1");
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    setflagData(flag);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", "");
    await server_post_data(get_all_admin_category, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setsStaffImageLinkData(
            APL_LINK + Response.data.message.data_imagelink_cate
          );
          seteditStaffData(Response.data.message.data_categoryInformation);
          setfilteredData(Response.data.message.data_categoryInformation);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (call_id, for_status_final, flag) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_delete", call_id);
    fd.append("flag_for", flag);
    fd.append("for_status_final", for_status_final);
    await server_post_data(update_category_master_active_status, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get(startDate, endDate, flagData, "0");
        }
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  const [showModal, setShowModal] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);

  const openModal = (guestName, index, flag) => {
    let tesr_show = " Not ";

    if (flag === 1) {
      if (guestName.active_status === 0) {
        tesr_show = "";
      }
      setShowModal(true);
    }

    setSelectedData(guestName);
    setClickedButton(guestName.category_name + tesr_show);
    setSelectedGuestIndex(index);
  };

  const confirmVIP = (index, flag) => {
    let allow_access_data = "0";
    if (SelectedData.active_status === 0) {
      allow_access_data = "1";
    }
    master_data_action_update(SelectedData.primary_id, allow_access_data, flag);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="flexCOntauiner">
                <Link to="/view_product">
                  <div className="pageNameDiv">
                    <p>Product Management</p>
                    <img src={GreyArrow} alt="Shopup Admin" />
                  </div>
                </Link>
                <div className="pageNameDiv px-0">
                  <p>Category Management</p>
                  <img src={GreyArrow} alt="Shopup Admin" />
                </div>
              </div>

              <div className="col-xl-2 col-sm-4 bottomAlgin">
                <Link to={`/Add_Category`}>
                  <button type="button" className="btnAddStaff add_search">
                    Add Category
                  </button>
                </Link>
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewStaff_head">
                  <div className="row m-0">
                    <div className="col-lg-2 col-sm-3 bottomAlgin">
                      <label className="labelView">
                        {ProductManagementPageText.Start_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="startDate"
                          type="date"
                          placeholder={ProductManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-3 bottomAlgin">
                      <label className="labelView">
                        {ProductManagementPageText.End_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="endDate"
                          type="date"
                          placeholder={ProductManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-3 bottomAlgin">
                      <button
                        type="button"
                        className="btnSearchNew"
                        onClick={() => search_data()}
                      >
                        {ProductManagementPageText.Search_text}
                      </button>
                    </div>

                    <div
                      className="col-xl-3 col-md-4 bottomAlgin"
                      style={{ marginLeft: "auto", marginRight: "0" }}
                    >
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <input
                          type="text"
                          placeholder={ProductManagementPageText.Guest_Filter}
                          value={searchfilter}
                          onInput={handleIaphabetnumberChange}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="theadStyle">
                                  Main Category Name
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <span> Category Name</span>
                                </div>
                              </th>

                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <span>Status</span>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      <img
                                        src={
                                          StaffImageLinkData +
                                          option.category_image
                                        }
                                        onError={(e) =>
                                          (e.target.src = GuestIcon)
                                        }
                                        alt="Staff avatar"
                                        className="staff-avatar image_show5050"
                                      />
                                      {option.main_catorgry_name}
                                    </div>
                                  </td>

                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.category_name}
                                    </div>
                                  </td>

                                  <td className="th2 tabledata">
                                    <div className="actionsAlign">
                                      <div className="guest_incenterActions borderRightRadius">
                                        <button
                                          type="button"
                                          className={`markVip Mark_Vip ${
                                            option.active_status === 1
                                              ? "vipMarked"
                                              : ""
                                          }`}
                                          onClick={() =>
                                            openModal(option, index, 1)
                                          }
                                        >
                                          <p>active</p>
                                        </button>
                                      </div>
                                      <div className="guest_incenterActions borderRightRadius">
                                        <Link
                                          to={`/edit_category/${option.primary_id}`}
                                        >
                                          <button
                                            type="button"
                                            className={`markVip Mark_Vip`}
                                          >
                                            <p>Edit</p>
                                          </button>
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>

                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="text_align_center border_1px"
                                >
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>
            Are you sure you want to mark{" "}
            <span className="color_primary">{clickedButton}</span> as Active
            Category?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(selectedGuestIndex, 1)}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CategoryMngmt;
