import React, { useState, useEffect } from "react";
import "./Css/StaffRights.css";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FolderImg from "../assets/Folder-pana.png";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
  empty_form,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  add_product_webapp_master,
  get_all_cate_product,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
function Edit_Product_Image() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [dynaicimage, setDynaicimage] = useState(null);
  const [dynaicimageold, setDynaicimageold] = useState(null);

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size <= 500 * 1024) {
        // 500KB in bytes
        handleError("File size is below the minimum limit (500KB).");
        return;
      }

      if (file.size > 2 * 1024 * 1024) {
        // 2MB in bytes
        handleError("File size exceeds the maximum limit (2MB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      handleError("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("product_id", editorDataMainID);
      fd_from.append("total_image_loop", 3);
      fd_from.append("image_product", 0);
      dynaicimageold.forEach(function (item, index) {
        let key = `doctor_image${index}_old`;
        let key1 = `doctor_image${index}_old1`;
        let key2 = `doctor_image${index}_old2`;

        fd_from.append(key, item.product_image);
        fd_from.append(key1, item.product_image1);
        fd_from.append(key2, item.product_image2);
      });
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/View_Product_list");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    const url = currentUrl;
    const parts = url.split("/");
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    fd.append("call_id", call_id);
    fd.append("flag", 1);
    await server_post_data(get_all_cate_product, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.product_data.length > 0) {
            setEditorDatMainID(
              Response.data.message.product_data[0].primary_id
            );
            let doctorImages = {};
            Response.data.message.search_results_product_images.forEach(
              function (item, index) {
                // Create a dynamic key
                let key = `doctor_image${index}_show`;
                // Construct the image URL
                let imageUrl =
                  APL_LINK +
                  Response.data.message.data_imagelink_cate +
                  item.product_image;

                // Add to the doctorImages object
                doctorImages[key] = imageUrl;
              }
            );
            setDynaicimage(doctorImages);
            setDynaicimageold(
              Response.data.message.search_results_product_images
            );
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="pageNameDiv">
                <p>
                  <p>Edit Product Image</p>
                </p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="col-xl-12 col-lg-12 ">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0">
                            <div className={`row `}>
                              <div className={`col-md-3`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Main Product Image
                                    <br></br>
                                    <span className="colorred">
                                      Size 600*600 max (2 MB)
                                    </span>
                                  </label>
                                  <div>
                                    <div className="">
                                      {dynaicimage &&
                                        dynaicimage.doctor_image0_show && (
                                          <img
                                            src={dynaicimage.doctor_image0_show}
                                            onError={(e) =>
                                              (e.target.src = FolderImg)
                                            }
                                            alt="Shopup Admin"
                                            className="image_show101"
                                          />
                                        )}
                                    </div>
                                    <input
                                      type="file"
                                      name="doctor_image0"
                                      onChange={handleFileChangedynamic(
                                        "doctor_image0"
                                      )}
                                      className={`${
                                        dynaicimage &&
                                        dynaicimage.doctor_image0_show
                                          ? ""
                                          : "trio_mandatory"
                                      } form-control`}
                                      accept=".jpg,.jpeg,.png"
                                      hidden
                                      id="imgInput"
                                    />
                                    <label
                                      className="imgInpBtn"
                                      htmlFor="imgInput"
                                      style={{ margin: "1rem" }}
                                    >
                                      {dynaicimage &&
                                      dynaicimage.doctor_image0_show
                                        ? "Change Image"
                                        : " Choose Image"}
                                    </label>

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Product Image 2 <br></br>
                                    <span className="colorred">
                                      Size 600*600 max (2 MB)
                                    </span>
                                  </label>
                                  <div>
                                    <div className="">
                                      {dynaicimage &&
                                        dynaicimage.doctor_image1_show && (
                                          <img
                                            src={dynaicimage.doctor_image1_show}
                                            onError={(e) =>
                                              (e.target.src = FolderImg)
                                            }
                                            alt="Shopup Admin"
                                            className="image_show101"
                                          />
                                        )}
                                    </div>
                                    <input
                                      type="file"
                                      name="doctor_image1"
                                      onChange={handleFileChangedynamic(
                                        "doctor_image1"
                                      )}
                                      className={`${
                                        dynaicimage &&
                                        dynaicimage.doctor_image1_show
                                          ? ""
                                          : "trio_mandatory"
                                      } form-control`}
                                      accept=".jpg,.jpeg,.png"
                                      hidden
                                      id="imgInput2"
                                    />
                                    <label
                                      className="imgInpBtn"
                                      htmlFor="imgInput2"
                                      style={{ margin: "1rem" }}
                                    >
                                      {dynaicimage &&
                                      dynaicimage.doctor_image1_show
                                        ? "Change Image"
                                        : " Choose Image"}
                                    </label>

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Product Image 3 <br></br>
                                    <span className="colorred">
                                      Size 600*600 max (2 MB)
                                    </span>
                                  </label>
                                  <div>
                                    <div className="">
                                      {dynaicimage &&
                                        dynaicimage.doctor_image2_show && (
                                          <img
                                            src={dynaicimage.doctor_image2_show}
                                            onError={(e) =>
                                              (e.target.src = FolderImg)
                                            }
                                            alt="Shopup Admin"
                                            className="image_show101"
                                          />
                                        )}
                                    </div>
                                    <input
                                      type="file"
                                      name="doctor_image2"
                                      onChange={handleFileChangedynamic(
                                        "doctor_image2"
                                      )}
                                      className={`${
                                        dynaicimage &&
                                        dynaicimage.doctor_image2_show
                                          ? ""
                                          : "trio_mandatory"
                                      } form-control`}
                                      accept=".jpg,.jpeg,.png"
                                      hidden
                                      id="imgInput3"
                                    />
                                    <label
                                      className="imgInpBtn"
                                      htmlFor="imgInput3"
                                      style={{ margin: "1rem" }}
                                    >
                                      {dynaicimage
                                        ? "Change Image"
                                        : " Choose Image"}
                                    </label>

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Product Image 4 <br></br>
                                    <span className="colorred">
                                      Size 600*600 max (2 MB)
                                    </span>
                                  </label>
                                  <div>
                                    <div className="">
                                      {dynaicimage &&
                                        dynaicimage.doctor_image3_show && (
                                          <img
                                            src={dynaicimage.doctor_image3_show}
                                            onError={(e) =>
                                              (e.target.src = FolderImg)
                                            }
                                            alt="Shopup Admin"
                                            className="image_show101"
                                          />
                                        )}
                                    </div>
                                    <input
                                      type="file"
                                      name="doctor_image3"
                                      onChange={handleFileChangedynamic(
                                        "doctor_image3"
                                      )}
                                      className={`${
                                        dynaicimage &&
                                        dynaicimage.doctor_image3_show
                                          ? ""
                                          : "trio_mandatory"
                                      } form-control`}
                                      accept=".jpg,.jpeg,.png"
                                      hidden
                                      id="imgInput4"
                                    />
                                    <label
                                      className="imgInpBtn"
                                      htmlFor="imgInput4"
                                      style={{ margin: "1rem" }}
                                    >
                                      {dynaicimage &&
                                      dynaicimage.doctor_image3_show
                                        ? "Change Image"
                                        : " Choose Image"}
                                    </label>

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="createRightBtnDiv">
                            <button
                              className="btn btn-primary Create_Right_Btn btnSave"
                              type="button"
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  "createRightsForm",
                                  add_product_webapp_master
                                )
                              }
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit_Product_Image;
