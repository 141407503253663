import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { EditorState, convertToRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import GreyArrow from "../assets/greyLeftAr.png";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Loader from "./Loader.js";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  handleAphabetsChange,
  handleNumbersChange,
  handleSuccessSession,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_update_packageinformation,
  get_packageinformation,
} from "../ServiceConnection/serviceconnection.js";
import { BlogWebsite_text } from "../CommonJquery/WebsiteText";
import Header from "./Header.js";
import { Link, useParams, useLocation } from "react-router-dom";

const AddPackage = () => {
  const { id } = useParams();
  const location = useLocation();
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffDataDetails, seteditStaffDataDetails] = useState([]);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editorData, setEditorData] = useState("");
  const [error_show, seterror_show] = useState("");
  const [editorError, setEditorError] = useState(false);

  useEffect(() => {
    if (id !== undefined) {
      const flag = "3";
      let call_id = id;
      master_data_get("", "", flag, call_id);
    }
  }, []);

  //new editor code starts
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const onEditorStateChange = (newState) => {
    setEditorState(newState);
    const contentState = newState.getCurrentContent();
    const htmlData = draftToHtml(convertToRaw(contentState));
    setEditorData(htmlData);
  };

  const setEditorContent = (htmlContent) => {
    const contentState = stateFromHTML(htmlContent);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", id);
    await server_post_data(get_packageinformation, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.data_packageInformation.length > 0) {
            seteditStaffDataDetails(
              Response.data.message.data_packageInformation[0]
            );
            setEditorDatMainID(
              Response.data.message.data_packageInformation[0].primary_id
            );
            setEditorData(
              Response.data.message.data_packageInformation[0].description
            );
            setEditorContent(
              Response.data.message.data_packageInformation[0].description
            );
            console.log(Response.data.message.data_packageInformation[0]);
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    const isEditorEmpty = !editorState.getCurrentContent().hasText();
    setEditorError(isEditorEmpty);

    if (vaild_data && !isEditorEmpty) {
      if (editorData.length > 0) {
        setshowLoaderAdmin(true);
        let fd_from = combiled_form_data(form_data, null);
        fd_from.append("main_id", editorDataMainID);
        fd_from.append("description", editorData);
        await server_post_data(url_for_save, fd_from)
          .then((Response) => {
            setshowLoaderAdmin(false);
            if (Response.data.error) {
              handleError(Response.data.message);
            } else {
              master_data_get("", "", "1", "0");
              handleSuccessSession(Response.data.message, "/view_Package_list");
            }
          })
          .catch((error) => {
            console.log(error);
            setshowLoaderAdmin(false);
          });
      } else {
        seterror_show("Please Fill The Mandatory Information");
      }
    } else {
      if (isEditorEmpty) {
        seterror_show("Please Fill The Mandatory Information");
      }
    }
  };

  return (
    <div>
      <Header />
      <div className="pageCntn_head">
        <div className="flexCOntauiner">
          <Link to="/view_Package_list">
            <div className="pageNameDiv">
              <p>Package Management</p>
              <img src={GreyArrow} alt="Shopup Admin" />
            </div>
          </Link>
          <div className="pageNameDiv px-0">
            {location.pathname.includes("/edit_Package") ? (
              <p>Edit Package</p>
            ) : (
              <p>Add Package</p>
            )}
            <img src={GreyArrow} alt="Shopup Admin" />
          </div>
        </div>
      </div>
      {showLoaderAdmin && <Loader />}

      <div className="page_body">
        <div className="create_staffRights ">
          <form id="addNewStaff" className="createRightsForm">
            <div className="event-box blog-data">
              <div className="form-group mt-4">
                <div className="row m-0">
                  <div className="col-md-3">
                    <label className="no_prsnl_id">
                      Package Name <span className="colorred">*</span>
                    </label>
                    <input
                      type="text"
                      id="package_name"
                      defaultValue={editStaffDataDetails.package_name || ""}
                      name="package_name"
                      onInput={handleAphabetsChange}
                      className="form-control trio_mandatory"
                      placeholder="Enter Package Name"
                    />
                    <span className="condition_error"></span>
                  </div>
                  <div className="col-md-3">
                    <label className="no_prsnl_id">
                      Package Price <span className="colorred">*</span>
                    </label>
                    <input
                      type="text"
                      id="package_price"
                      defaultValue={editStaffDataDetails.package_price || ""}
                      name="package_price"
                      onInput={handleNumbersChange}
                      className="form-control trio_mandatory"
                      placeholder="Package Price"
                    />
                    <span className="condition_error"></span>
                  </div>
                  <div className="col-md-3 ">
                    <label className="no_prsnl_id">
                      Package Timing <span className="colorred">*</span>
                    </label>
                    <select
                      id="type_of_days"
                      name="type_of_days"
                      className="form-control trio_mandatory"
                      style={{ appearance: "auto" }}
                    >
                      <option
                        value="0"
                        selected={
                          editStaffDataDetails.type_of_days === 0 ? true : false
                        }
                      >
                        Monthly
                      </option>
                      <option
                        value="1"
                        selected={
                          editStaffDataDetails.type_of_days === 1 ? true : false
                        }
                      >
                        Quarterly
                      </option>
                      <option
                        value="2"
                        selected={
                          editStaffDataDetails.type_of_days === 2 ? true : false
                        }
                      >
                        Yearly
                      </option>
                    </select>
                    <span className="condition_error"></span>
                  </div>
                  <div className="col-md-3">
                    <label className="no_prsnl_id">
                      Package SMS <span className="colorred">*</span>
                    </label>
                    <input
                      type="text"
                      name="sms_credit"
                      defaultValue={editStaffDataDetails.sms_credit || ""}
                      id="sms_credit"
                      onInput={handleNumbersChange}
                      className="form-control trio_mandatory"
                      placeholder="Total SMS"
                    />
                    <span className="condition_error"></span>
                  </div>

                  <div className={`col-sm-12 mt-4`}>
                    <div className="  display_grid">
                      <label className="no_prsnl_id">
                        {BlogWebsite_text.Description}
                        <span className="colorred">*</span>
                      </label>
                      <div>
                        <Editor
                          editorState={editorState}
                          wrapperClassName="demo-wrapper form-control"
                          editorClassName="demo-editor"
                          onEditorStateChange={onEditorStateChange}
                        />
                      </div>
                      <span className="condition_error mt-1">{error_show}</span>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4">
                    <div className="addBlogBtnDiv">
                      <button
                        type="button"
                        onClick={() =>
                          handleSaveChangesdynamic(
                            "addNewStaff",
                            save_update_packageinformation
                          )
                        }
                        className="btn btn-secondary mt-3  save-cap-btn"
                      >
                        {BlogWebsite_text.save_txt}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddPackage;
