import React, { useEffect } from "react";
import "./App.css";
import "./Components/Css/Dashboard.css";
import "./Components/Css/Calender.css";
import "./Components/Css/Login.css";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Login from "./Components/Login";
import ForgotPassword from "./Components/ForgotPassword";
import ChangePassword from "./Components/ChangePassword";
import Analytics from "./Components/Analytics";
import Manuals from "./Components/Manuals";
import Addfaqs from "./Components/Add_Faqs";
import ViewFaq from "./Components/ViewFaq";
import ViewKB from "./Components/ViewKB";
import AddKB from "./Components/AddKB";
import Report from "./Components/Reports";
import WebsiteMngt from "./Components/WebsiteMngmt";
import Add_Product from "./Components/Add_Product";
import ProductMngmt from "./Components/ProductMngmt";
import BusinessOwnerMngmt from "./Components/BusinessOwnerMngmt";
import BusinessListMngmt from "./Components/BusinessListMngmt";
import ContactMngmt from "./Components/ContactMngmt";
import RequestMngmt from "./Components/RequestMngmt";
import AddStaff from "./Components/AddStaff";
import ViewStaff from "./Components/ViewStaff";
import AddDesignation from "./Components/AddDesignation";
import ViewDesignation from "./Components/ViewDesignation";
import AddPackage from "./Components/AddPackage";
import PackageMngmt from "./Components/PackageMngmt";
import "../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AddBusiness from "./Components/AddBusiness";
import CategoryMngmt from "./Components/CategoryMngmt";
import Add_Category from "./Components/Add_Category";
import Add_Payment from "./Components/Add_Payment";
import Edit_Product_Image from "./Components/Edit_Product_Image";
import Edit_Product from "./Components/Edit_Product";
import ViewSearchMaster from "./Components/ViewSearchMaster";
import Add_SearchMaster from "./Components/Add_SearchMaster";
import Help from "./Components/Help";
import CreateNewTicket from "./Components/CreateTicket";
import ReplyToTicket from "./Components/ReplyToTickect";

function App() {
  useEffect(() => {
    console.log("Effect is running");
    const inputElements = document.getElementsByTagName("input");
    Array.from(inputElements).forEach((input) => {
      input.setAttribute("autocomplete", "off");
    });
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* Working Screen */}

          {/* Login Screens */}
          <Route path="/" element={<Login />} />
          <Route path="/Forgot_Password" element={<ForgotPassword />} />
          <Route path="/Change_Password/:id" element={<ChangePassword />} />

          {/* Working Pages */}
          <Route path="/view_business_owner" element={<BusinessOwnerMngmt />} />
          <Route path="/view_contact_us" element={<ContactMngmt />} />
          <Route path="/view_request_us" element={<RequestMngmt />} />

          {/* Help And Support Pages */}
          <Route path="/Manuals" element={<Manuals />} />
          <Route path="/Add_Faq" element={<Addfaqs />} />
          <Route path="/View_Faq" element={<ViewFaq />} />
          <Route path="/View_KB" element={<ViewKB />} />
          <Route path="/Add_KB" element={<AddKB />} />
          <Route path="/Edit_KB/:id" element={<AddKB />} />
          <Route path="/Edit_Faq/:id" element={<Addfaqs />} />
          <Route path="/Website_Management" element={<WebsiteMngt />} />
          <Route path="/View_Staff" element={<ViewStaff />} />
          <Route path="/Add_Staff" element={<AddStaff />} />
          <Route path="/edit_staff/:id" element={<AddStaff />} />
          <Route path="/Add_Designation" element={<AddDesignation />} />
          <Route path="/Edit_Designation/:id" element={<AddDesignation />} />
          <Route path="/ViewDesignation" element={<ViewDesignation />} />
          <Route path="/view_Package_list" element={<PackageMngmt />} />
          <Route path="/add_Package" element={<AddPackage />} />
          <Route path="/edit_Package/:id" element={<AddPackage />} />

          <Route path="/view_business_list" element={<BusinessListMngmt />} />
          <Route path="/Add_paymant/:id" element={<Add_Payment />} />
          <Route path="/Add_Business" element={<AddBusiness />} />
          <Route path="/View_Category_list" element={<CategoryMngmt />} />

          <Route path="/Add_Category" element={<Add_Category />} />
          <Route path="/edit_category/:id" element={<Add_Category />} />

          <Route path="/add_product" element={<Add_Product />} />

          <Route path="/edit_product/:id" element={<Edit_Product />} />

          <Route path="/View_Product_list" element={<ProductMngmt />} />
          <Route
            path="/edit_product_images/:id"
            element={<Edit_Product_Image />}
          />
          {/* Guest Management Pages */}

          <Route path="/ViewSearchMaster" element={<ViewSearchMaster />} />
          <Route path="/Add_SearchMaster" element={<Add_SearchMaster />} />
          <Route path="/Edit_SearchMaster/:id" element={<Add_SearchMaster />} />

          {/* Dashboard Screens */}
          <Route path="/Dashboard" element={<Analytics />} />
          <Route path="/View_Reports" element={<Report />} />
          <Route path="/help" element={<Help />} />
          <Route path="/Manuals/Create_Ticket" element={<CreateNewTicket />} />
          <Route path="/Manuals/Reply_Ticket/:ticketId" element={<ReplyToTicket />} />

          {/* Website Settings Pages */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
