import axios from "axios";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
const appauth_key = "barlays@2029";
let APL_LINK = "http://192.168.1.9:8000/";
APL_LINK = "https://backend.shopupapp.in/";

let Website_URL = "https://www.shopupapp.in";
let local_server_link_react = APL_LINK + "api/admin_link/";

// API functions
const login_to_superadmin = local_server_link_react + "login_to_superadmin";
const forgot_password = local_server_link_react + "forgot_password";
const check_resetlink_vaild = local_server_link_react + "check_resetlink_vaild";
const change_password_by_forgot =
  local_server_link_react + "change_password_by_forgot";

const get_all_ownersinformation =
  local_server_link_react + "get_all_ownersinformation";
const get_all_admin_contect = local_server_link_react + "get_all_admin_contect";
const save_update_faq = local_server_link_react + "save_update_faq";
const get_all_faq = local_server_link_react + "get_all_faq";
const save_update_knowledgebase =
  local_server_link_react + "save_update_knowledgebase";
const get_all_knowledgebase = local_server_link_react + "get_all_knowledgebase";
const save_update_blog = local_server_link_react + "save_update_blog";
const get_all_blogs = local_server_link_react + "get_all_blogs";
const save_update_seo = local_server_link_react + "save_update_seo";
const get_all_seo = local_server_link_react + "get_all_seo";
const save_update_imagelink = local_server_link_react + "save_update_imagelink";
const get_all_imagelink = local_server_link_react + "get_all_imagelink";
const delete_package_data = local_server_link_react + "delete_package_data";
const delete_website_master_data =
  local_server_link_react + "delete_website_master_data";
const get_all_subscribe = local_server_link_react + "get_all_subscribe";
const get_all_Vacancy = local_server_link_react + "get_all_Vacancy";
const save_update_Vacancy = local_server_link_react + "save_update_Vacancy";
const update_data_career = local_server_link_react + "update_data_career";
const save_update_staff = local_server_link_react + "save_update_staff";
const get_all_staff = local_server_link_react + "get_all_staff";
const action_update_staff = local_server_link_react + "action_update_staff";
const save_update_designtion =
  local_server_link_react + "save_update_designtion";
const get_all_designation = local_server_link_react + "get_all_designation";
const get_all_searchmaster = local_server_link_react + "get_all_searchmaster";
const save_update_packageinformation =
  local_server_link_react + "save_update_packageinformation";
const get_packageinformation =
  local_server_link_react + "get_packageinformation";
const get_all_businessinformation =
  local_server_link_react + "get_all_businessinformation";
const update_apk_link_data = local_server_link_react + "update_apk_link_data";
const get_business_payment_list =
  local_server_link_react + "get_business_payment_list";
const add_business_payment_admin =
  local_server_link_react + "add_business_payment_admin";
const get_category_packege = local_server_link_react + "get_category_packege";
const add_business_information =
  local_server_link_react + "add_business_information";
const update_product_master_active_status =
  local_server_link_react + "update_product_master_active_status";
const get_all_admin_category =
  local_server_link_react + "get_all_admin_category";
const update_category_master_active_status =
  local_server_link_react + "update_category_master_active_status";
const add_category_super_admin =
  local_server_link_react + "add_category_super_admin";
const get_all_cate_product = local_server_link_react + "get_all_cate_product";
const get_all_admin_product = local_server_link_react + "get_all_admin_product";
const add_product_webapp_master =
  local_server_link_react + "add_product_webapp_master";
const update_product_images = local_server_link_react + "update_product_images";
const get_all_analytics_admin =
  local_server_link_react + "get_all_analytics_admin";
const get_all_businessReport =
  local_server_link_react + "get_all_businessReport";
const get_all_paymentreport = local_server_link_react + "get_all_paymentreport";
const update_vendor_active_status = local_server_link_react + "update_vendor_active_status";
const get_all_tickets = local_server_link_react + "get_all_tickets";
const get_save_update_tickets = local_server_link_react + "get_save_update_tickets";
const resolve_ticket_by_user = local_server_link_react + "resolve_ticket_by_user";
const get_save_update_tickets_details = local_server_link_react + "get_save_update_tickets_details";

//check first

const upload_product_excel_data =
  local_server_link_react + "upload_product_excel_data";

const save_update_searchmaster =
  local_server_link_react + "save_update_searchmaster";
// old apis

const retrievedAdminId = retrieveData("admin_id");
const server_post_data = async (url_for, form_data) => {
  if (form_data === null) {
    form_data = new FormData();
  }
  form_data.append("admin_id", retrievedAdminId);
  if (form_data.get("data_call") !== null) {
    form_data.append("admin_id", retrievedAdminId);
  }
  return axios.post(url_for, form_data);
};

export {
  APL_LINK,
  Website_URL,
  appauth_key,
  server_post_data,
  login_to_superadmin,
  forgot_password,
  check_resetlink_vaild,
  change_password_by_forgot,
  save_update_staff,
  get_all_staff,
  action_update_staff,
  get_all_designation,
  save_update_designtion,
  save_update_faq,
  get_all_faq,
  save_update_knowledgebase,
  get_all_knowledgebase,
  get_all_searchmaster,
  save_update_searchmaster,
  save_update_blog,
  get_all_blogs,
  save_update_seo,
  get_all_seo,
  save_update_imagelink,
  get_all_imagelink,
  delete_website_master_data,
  get_all_Vacancy,
  save_update_Vacancy,
  update_data_career,
  get_all_subscribe,
  get_all_cate_product,
  add_product_webapp_master,
  get_all_ownersinformation,
  get_all_admin_contect,
  save_update_packageinformation,
  get_packageinformation,
  get_all_businessinformation,
  update_apk_link_data,
  add_business_information,
  get_category_packege,
  get_all_admin_product,
  get_all_admin_category,
  add_category_super_admin,
  get_business_payment_list,
  add_business_payment_admin,
  upload_product_excel_data,
  update_product_master_active_status,
  update_category_master_active_status,
  get_all_businessReport,
  get_all_analytics_admin,
  get_all_paymentreport,
  update_product_images,
  delete_package_data,
  update_vendor_active_status,
  get_all_tickets,
  get_save_update_tickets,
  resolve_ticket_by_user,
  get_save_update_tickets_details,
};
