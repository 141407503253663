import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import Delete from "../assets/delete.svg";
import Edit from "../assets/edit_square.png";
import {
  options_search,
  GuestManagementPageText,
} from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  get_packageinformation,
  delete_package_data,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  handleAphabetsChange,
  handleEmailChange,
  handleNumbersChange,
  computeplussevendays,
  handleSuccess,
} from "../CommonJquery/CommonJquery.js";
function PackageMngmt() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [SelectedData, setSelectedData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Name"); // State to hold the selected value
  const [RupeesLeft, setsRupeesLeft] = useState("");
  const [RupeesRight, setsRupeesRight] = useState("");
  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const handleClose = () => {
    setModalShow(false);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", selectedValue);
    await server_post_data(get_packageinformation, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Response.data.message.data_packageInformation.forEach((subObj) => {
            if (subObj.type_of_days === 0) {
              subObj.days_positiontest = "Monthly";
            } else if (subObj.type_of_days === 1) {
              subObj.days_positiontest = "Quarterly";
            } else {
              subObj.days_positiontest = "Yearly";
            }
          });
          setsRupeesLeft(Response.data.message.rupees_left);
          setsRupeesRight(Response.data.message.rupess_right);
          seteditStaffData(Response.data.message.data_packageInformation);
          setfilteredData(Response.data.message.data_packageInformation);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleShow = (option) => {
    setSelectedData(option.primary_id);
    setModalShow(true);
  };

  const handleClickDelete = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("call_id", SelectedData);
    console.log(SelectedData);
    await server_post_data(delete_package_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          handleClose();
          handleSuccess(Response.data.message);
          master_data_get(startDate, endDate, 1, 0);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>Package Management</p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
              <div className="col-xl-2 col-sm-4 bottomAlgin">
                <Link to={`/add_Package`}>
                  <button type="button" className="btnAddStaff add_search">
                    Add Package
                  </button>
                </Link>
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewStaff_head">
                  <div className="row m-0">
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {GuestManagementPageText.Start_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="startDate"
                          type="date"
                          placeholder={GuestManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {GuestManagementPageText.End_Date}
                      </label>
                      <div
                        className="person__calenderFrame_image image_icon_position1 "
                        style={{ cursor: "pointer" }}
                      >
                        <input
                          id="endDate"
                          type="date"
                          placeholder={GuestManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>

                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <div className="inputDiv2 p-0">
                        <button
                          type="button"
                          className="btnSearch w-100 h-100"
                          style={{ top: "0", right: "0" }}
                          onClick={() => search_data()}
                        >
                          {GuestManagementPageText.Search_text}
                        </button>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-md-4 mt-3"
                      style={{ marginLeft: "auto", marginRight: "0" }}
                    >
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <input
                          type="text"
                          placeholder={GuestManagementPageText.Guest_Filter}
                          value={searchfilter}
                          onInput={handleIaphabetnumberChange}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <span>S.No</span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <span>Package Name</span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Price</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Type Of Days</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Total SMS</div>
                              </th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {index + 1}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.package_name}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {RupeesLeft}
                                      {option.package_price} {RupeesRight}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.days_positiontest}
                                    </div>
                                  </td>
                                  <td className="th2 tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      <p>{option.sms_credit}</p>
                                    </div>
                                  </td>
                                  <td className="th4">
                                    <div className="recentReservBtns">
                                      <Link
                                        to={`/edit_Package/${option.primary_id}`}
                                      >
                                        <button
                                          className="mb-2"
                                          style={{ width: "90px" }}
                                        >
                                          <p>Edit</p>
                                          <img
                                            style={{
                                              width: "18px",
                                              height: "18px",
                                            }}
                                            src={Edit}
                                            alt="Shopup Admin"
                                          />
                                        </button>
                                      </Link>
                                      <button
                                        className="mb-2"
                                        style={{ width: "90px" }}
                                        onClick={() => handleShow(option)}
                                      >
                                        <p>Delete</p>
                                        <img src={Delete} alt="Shopup Admin" />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="text_align_center border_1px"
                                >
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          <p className="modalText">Do You Want to Delete This Data?</p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            No
            {/* {BlogWebsite_text.no_txt} */}
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={handleClickDelete}
          >
            <label
              style={{ cursor: "pointer" }}
              // htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Yes
              {/* {BlogWebsite_text.Yes_txt} */}
            </label>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PackageMngmt;
