import React, { useEffect, useState, useRef } from "react";
import { Pagination } from "react-bootstrap";
import Header from "../Components/Header.js";
import Loader from "../Components/Loader.js";
import "./Css/Analytics.css";
import Delete from "../assets/delete.svg";
import Edit from "../assets/edit_square.png";
import GreyArrow from "../assets/greyLeftAr.png";
import {
  server_post_data,
  get_all_knowledgebase,
  save_update_knowledgebase,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  formatDateStringdot,
  handleConfimDeleteClick,
} from "../CommonJquery/CommonJquery.js";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
function ViewFaq() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [primaryID, setprimaryID] = useState(null);

  const modalShowHandle = (id) => {
    setModalShow(true);
    setprimaryID(id);
  };

  const handleClose = () => {
    setModalShow(false);
    setprimaryID(null);
  };

  useEffect(() => {
    const flag = "1";
    const call_id = "0";
    master_data_get("", "", flag, call_id, "");
  }, []);

  const tableRef = useRef(null);

  const master_data_get = async (
    start_date,
    end_date,
    flag,
    call_id,
    select_type
  ) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_all_knowledgebase, fd)
      .then((Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditStaffData(Response.data.message.data);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);

  const totalPageCount = Math.ceil(editStaffData.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationItems = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPageCount; i++) {
      pageNumbers.push(i);
    }

    if (totalPageCount <= 5) {
      return pageNumbers.map((number) => (
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => paginate(number)}
        >
          {number}
        </Pagination.Item>
      ));
    } else {
      const delta = 2;
      const left = currentPage - delta;
      const right = currentPage + delta + 1;
      let pages = [];
      let isEllipsisShown = false;

      for (let i = 1; i <= totalPageCount; i++) {
        if (i === 1 || i === totalPageCount || (i >= left && i < right)) {
          pages.push(i);
        } else if (!isEllipsisShown) {
          pages.push(-1); // -1 indicates ellipsis
          isEllipsisShown = true;
        }
      }

      return pages.map((number, index) => {
        if (number === -1) {
          return <Pagination.Ellipsis key={index} />;
        }
        return (
          <Pagination.Item
            key={index}
            active={number === currentPage}
            onClick={() => paginate(number)}
          >
            {number}
          </Pagination.Item>
        );
      });
    }
  };

  const master_data_action = async (call_id) => {
    // if (handleConfimDeleteClick()) {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("main_id", call_id);
    fd.append("flag", "3");
    await server_post_data(save_update_knowledgebase, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
    // }
  };
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>View Knowledge Base</p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
              <Link to="/Add_KB">
                <button type="button" className="btnAddStaff darkBg add_FAQ">
                  Add KB
                </button>
              </Link>
            </div>

            <div className="page_body">
              <div className="analytics">
                <div className="analytics_container mt-2">
                  <div className="analyticsCardsContainer">
                    <div className="feedBackTable">
                      <div className="feedBackTable_container">
                        <table
                          id="myTable"
                          className="display table"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              <th scope="col" className="th3">
                                S. No.
                              </th>
                              <th scope="col">Entry Date</th>
                              <th scope="col">Topic</th>
                              <th scope="col">Details</th>
                              <th className="th4">Action</th>
                            </tr>
                            <tr style={{ height: "25px" }}></tr>
                          </thead>
                          <tbody>
                            {editStaffData.map((item, index) => (
                              <React.Fragment key={index}>
                                <tr>
                                  <td className="th3">
                                    <div className="recentANme">
                                      <p>{index + 1}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="recentContact">
                                      <p>
                                        {formatDateStringdot(item.entry_date)}
                                      </p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="recentContact">
                                      <p>{item.topic_name}</p>
                                    </div>
                                  </td>
                                  <td>
                                    <div className="recentContact">
                                      <p>
                                        {item.knowledgebase_data.slice(0, 20)}
                                        {item.knowledgebase_data.length > 20
                                          ? `...`
                                          : ``}
                                      </p>
                                    </div>
                                  </td>

                                  <td className="th4">
                                    <div className="recentReservBtns">
                                      <Link to={`/Edit_KB/${item.primary_id}`}>
                                        <button
                                          className="mb-2"
                                          style={{ width: "90px" }}
                                        >
                                          <p>Edit</p>
                                          <img
                                            style={{
                                              width: "18px",
                                              height: "18px",
                                            }}
                                            src={Edit}
                                            alt="Shopup Admin"
                                          />
                                        </button>
                                      </Link>
                                      <button
                                        className="mb-2"
                                        style={{ width: "90px" }}
                                        onClick={() =>
                                          modalShowHandle(item.primary_id)
                                        }
                                      >
                                        <p>Delete</p>
                                        <img src={Delete} alt="Shopup Admin" />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  style={{ height: "1rem", boxShadow: "none" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                        <Pagination>
                          <div className="paginationContainer">
                            <div className="nxtBttnTble">
                              {currentPage !== 1 ? (
                                <button
                                  className="btn btn-primary"
                                  onClick={() =>
                                    setCurrentPage((prev) =>
                                      prev > 1 ? prev - 1 : prev
                                    )
                                  }
                                >
                                  Previous
                                </button>
                              ) : null}
                            </div>
                            <div className="d-flex gap-2">
                              {renderPaginationItems()}
                            </div>
                          </div>
                        </Pagination>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalShow}
        className="confirmModal"
        centered
        backdrop="static"
        keyboard={false}
        onHide={handleClose}
      >
        <Modal.Header className="confirmModalHeader" closeButton></Modal.Header>
        <Modal.Body className="confirmBody">
          <p className="modalText">Do You Want to Delete This Data?</p>
        </Modal.Body>
        <Modal.Footer className="confirmModalFooter">
          <Button
            className={`closeConfirmAeBtn closeConfirmBtn`}
            onClick={handleClose}
          >
            No
            {/* {BlogWebsite_text.no_txt} */}
          </Button>
          <Button
            className={`confirmAeBtn Confirm_Deactive`}
            onClick={() => master_data_action(primaryID)}
          >
            <label
              style={{ cursor: "pointer" }}
              // htmlFor={`dynamic_id${SelectedData.primary_id}`}
            >
              Yes
              {/* {BlogWebsite_text.Yes_txt} */}
            </label>
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewFaq;
