import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import GuestIcon from "../assets/guestIcon.png";
import { CSVLink } from "react-csv";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import {
  options_search,
  BusinessOwnerManagementPageText,
} from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  get_all_ownersinformation,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  handleAphabetsChange,
  handleEmailChange,
  handleNumbersChange,
  computeplussevendays,
  formatDateStringdot,
} from "../CommonJquery/CommonJquery.js";
function BusinessOwnerMngmt() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [selectedValue, setSelectedValue] = useState("Name"); // State to hold the selected value
  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", selectedValue);
    fd.append("search_data", document.getElementById("search_data").value);
    await server_post_data(get_all_ownersinformation, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          seteditStaffData(Response.data.message.data_ownerInformation);
          setfilteredData(Response.data.message.data_ownerInformation);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };

  const exportToCSV = (csvData, fileName, fileExtension) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>{BusinessOwnerManagementPageText.Guest_Management}</p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
              <div
              // className={` ${
              //   customDateActive ? "col-sm-8" : "col-xl-3"
              // } alignCol bottomAlgin`}
              >
                <div className="pageActionBtns">
                  <button
                    className="CSVBTN"
                    onClick={() =>
                      exportToCSV(filteredData, "download_excel", ".xlsx")
                    }
                  >
                    Excel
                  </button>

                  <CSVLink data={filteredData} target="_blank">
                    <button className="CSVBTN">CSV</button>
                  </CSVLink>
                  {/* <button className="printReposrr" onClick={handlePrint}>
                    <img src={PrintOrng} alt="Shopup Admin" />
                  </button> */}
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewStaff_head">
                  <div className="row m-0">
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {BusinessOwnerManagementPageText.Start_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="startDate"
                          type="date"
                          placeholder={BusinessOwnerManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {BusinessOwnerManagementPageText.End_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="endDate"
                          type="date"
                          placeholder={BusinessOwnerManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 bottomAlgin">
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <select id="selectFilter" onChange={handleSelect}>
                          {options_search.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-8 bottomAlgin">
                      <div className="inputDiv2">
                        <img src={Search} alt="Shopup Admin" />
                        <input
                          type="text"
                          id="search_data"
                          onInput={(e) => {
                            if (selectedValue === options_search[0].value) {
                              handleAphabetsChange(e);
                            } else if (
                              selectedValue === options_search[1].value
                            ) {
                              handleEmailChange(e);
                            } else if (
                              selectedValue === options_search[2].value
                            ) {
                              handleNumbersChange(e);
                            }
                          }}
                          placeholder={
                            BusinessOwnerManagementPageText.Search_Guest
                          }
                        />
                        <button
                          type="button"
                          className="btnSearch"
                          onClick={() => search_data()}
                        >
                          {BusinessOwnerManagementPageText.Search_text}
                        </button>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-md-4 mt-3"
                      style={{ marginLeft: "auto", marginRight: "0" }}
                    >
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <input
                          type="text"
                          placeholder={
                            BusinessOwnerManagementPageText.Guest_Filter
                          }
                          value={searchfilter}
                          onInput={handleIaphabetnumberChange}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  {BusinessOwnerManagementPageText.Enrty_date}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <img src={GuestIcon} alt="Shopup Admin" />
                                  <span>
                                    {BusinessOwnerManagementPageText.Guest_Name}
                                  </span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {
                                    BusinessOwnerManagementPageText.Contact_Details
                                  }
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {BusinessOwnerManagementPageText.Email_ID}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {BusinessOwnerManagementPageText.Booking_text}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="th1 tabledata">
                                    <div className="guest_incenter borderLeftRadius">
                                      {formatDateStringdot(option.entry_date)}
                                    </div>
                                  </td>
                                  <td className="th1 tabledata">
                                    <div className="guest_incenter borderLeftRadius">
                                      {option.owner_name}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.owner_moblie_no}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.owner_email}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.no_of_business}
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="text_align_center border_1px"
                                >
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BusinessOwnerMngmt;
