import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import { Link } from "react-router-dom";
import GuestIcon from "../assets/guestIcon.png";
import { Modal, Button } from "react-bootstrap";
import { ProductManagementPageText } from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  get_all_admin_product,
  upload_product_excel_data,
  update_product_master_active_status,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  computeplussevendays,
} from "../CommonJquery/CommonJquery.js";
function ProductMngmt() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [itemDatail, setItemDatail] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [Categoryfilter, setCategoryFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [flagData, setflagData] = useState("1");
  const [selectedValue, setSelectedValue] = useState("0"); // State to hold the selected value
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    setflagData(flag);
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", selectedValue);
    await server_post_data(get_all_admin_product, fd)
      .then((Response) => {
        console.log(Response);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setsStaffImageLinkData(
            APL_LINK + Response.data.message.data_imagelink
          );
          seteditStaffData(Response.data.message.data_productInformation);
          setfilteredData(Response.data.message.data_productInformation);
          setCategoryFilter(Response.data.message.data_categoryInformation);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const master_data_action_update = async (call_id, for_status_final, flag) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_delete", call_id);
    fd.append("flag_for", flag);
    fd.append("for_status_final", for_status_final);
    fd.append("business_id", SelectedData.add_by);
    await server_post_data(update_product_master_active_status, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get(startDate, endDate, flagData, "0");
        }
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });
    if (searchValue !== "") {
      setfilteredData(filteredDatashow);
    }
  };
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);

  const openModal = (guestName, index, flag) => {
    let tesr_show = "  ";
    console.log("hit this line", guestName.active_status, flag);
    if (flag === 1) {
      if (guestName.active_status === 1) {
        tesr_show = " Not ";
      }
      setShowModal(true);
    } else if (flag === 2) {
      setShowModal1(true);
    }

    setSelectedData(guestName);
    setClickedButton(guestName.product_name + tesr_show);
    setSelectedGuestIndex(index);
  };

  const confirmVIP = (index, flag) => {
    let allow_access_data = "0";
    if (SelectedData.active_status === 0) {
      allow_access_data = "1";
    }
    master_data_action_update(SelectedData.primary_id, allow_access_data, flag);
  };
  const confirmVIP1 = (index, flag) => {
    let allow_access_data = "1";

    master_data_action_update(SelectedData.primary_id, allow_access_data, flag);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModal1(false);
    setShowModal3(false);
  };

  const handleItemdetail = (index) => {
    setItemDatail(filteredData[index]);
    console.log(itemDatail);
    setShowModal3(true);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <Link to="/View_Product_list">
                <div className="pageNameDiv">
                  <p>{ProductManagementPageText.Guest_Management}</p>
                  <img src={GreyArrow} alt="Shopup Admin" />
                </div>
              </Link>
              <div className="col-xl-3 col-sm-4 flexGap">
                <Link to={`/View_Category_list`}>
                  <button type="button" className="btnAddStaff add_search">
                    Category
                  </button>
                </Link>
                <Link to={`/add_product`}>
                  <button type="button" className="btnAddStaff add_search">
                    Add Product
                  </button>
                </Link>
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewStaff_head">
                  <div className="row m-0">
                    <div className="col-lg-2 col-sm-3 bottomAlgin">
                      <label className="labelView">
                        {ProductManagementPageText.Start_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="startDate"
                          type="date"
                          placeholder={ProductManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-3 bottomAlgin">
                      <label className="labelView">
                        {ProductManagementPageText.End_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="endDate"
                          type="date"
                          placeholder={ProductManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-3 bottomAlgin">
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <select id="selectFilter" onChange={handleSelect}>
                          {Categoryfilter.length > 0 &&
                            Categoryfilter.map((option) => (
                              <option
                                key={option.primary_id}
                                value={option.primary_id}
                              >
                                {option.category_name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-3 bottomAlgin">
                      <button
                        type="button"
                        className="btnSearchNew"
                        onClick={() => search_data()}
                      >
                        {ProductManagementPageText.Search_text}
                      </button>
                    </div>

                    <div
                      className="col-xl-3 col-md-4 bottomAlgin"
                      style={{ marginLeft: "auto", marginRight: "0" }}
                    >
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <input
                          type="text"
                          placeholder={ProductManagementPageText.Guest_Filter}
                          value={searchfilter}
                          onInput={handleIaphabetnumberChange}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="theadStyle">
                                  {ProductManagementPageText.Booking_text}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {ProductManagementPageText.pro_image}
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <img src={GuestIcon} alt="Shopup Admin" />
                                  <span>
                                    {ProductManagementPageText.Guest_Name}
                                  </span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  {ProductManagementPageText.Contact_Details}
                                </div>
                              </th>

                              <th scope="col">
                                <div className="theadStyle">
                                  {ProductManagementPageText.action_items}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.sub_category_data}
                                    </div>
                                  </td>
                                  <td className="th1 tabledata">
                                    <div className="guest_incenter2 borderLeftRadius">
                                      <img
                                        src={
                                          StaffImageLinkData +
                                          option.product_image
                                        }
                                        onError={(e) =>
                                          (e.target.src = GuestIcon)
                                        }
                                        alt="Staff avatar"
                                        className="staff-avatar image_show5050"
                                      />
                                    </div>
                                  </td>
                                  <td className="th1 tabledata">
                                    <div className="guest_incenter2 borderLeftRadius">
                                      {option.product_name}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.product_qty}{" "}
                                      {option.product_qty_unit}
                                    </div>
                                  </td>

                                  <td className="th2 tabledata">
                                    <div className="actionsAlign">
                                      <div className="guest_incenterActions borderRightRadius">
                                        <button
                                          type="button"
                                          className={`markVip Mark_Vip ${
                                            option.active_status === 1
                                              ? "vipMarked"
                                              : "vipMarkedred"
                                          }`}
                                          onClick={
                                            option.active_status !== 0 &&
                                            option.active_status !== 4
                                              ? () =>
                                                  openModal(option, index, 1)
                                              : () =>
                                                  openModal(option, index, 2)
                                          }
                                        >
                                          <p>{option.active_status_name}</p>
                                        </button>
                                      </div>

                                      <div className="guest_incenterActions borderRightRadius">
                                        <Link
                                          to={`/edit_product_images/${option.primary_id}`}
                                        >
                                          <button
                                            type="button"
                                            className={`markVip Mark_Vip`}
                                          >
                                            <p>Upload Image</p>
                                          </button>
                                        </Link>
                                      </div>
                                      <div className="guest_incenterActions borderRightRadius">
                                        <button
                                          type="button"
                                          className={`markVip Mark_Vip`}
                                          onClick={() =>
                                            handleItemdetail(index)
                                          }
                                        >
                                          <p>Details</p>
                                        </button>
                                      </div>
                                      <div className="guest_incenterActions borderRightRadius">
                                        <Link
                                          to={`/edit_product/${option.primary_id}`}
                                        >
                                          <button
                                            type="button"
                                            className={`markVip Mark_Vip`}
                                          >
                                            <p>Edit</p>
                                          </button>
                                        </Link>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="text_align_center border_1px"
                                >
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>
            Are you sure you want to mark{" "}
            <span className="color_primary">{clickedButton}</span> as Active
            Product?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(selectedGuestIndex, 1)}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal1} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>
            Are you sure you want to mark{" "}
            <span className="color_primary">{clickedButton}</span> as Active
            Product?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP1(selectedGuestIndex, 2)}
          >
            Active
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal3} onHide={closeModal} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="productDetails">
            <div className="productDetails_container">
              <div className="productHead">
                <h5>
                  <span>Product: </span>
                  {itemDatail.product_name}
                </h5>
                <div className="productImg">
                  <img
                    src={`${StaffImageLinkData}${itemDatail.product_image}`}
                  />
                </div>
              </div>
              <div className="productdetail">
                <p>
                  <span>Catagory: </span>
                  {itemDatail.main_category_data}
                </p>
                <p>
                  <span>Sub-Catagory: </span>
                  {itemDatail.sub_category_data}
                </p>
                <p>
                  <span>Quantity: </span>
                  {itemDatail.product_qty} {itemDatail.product_qty_unit}
                </p>
                <p>
                  <span>Description: </span>
                  {itemDatail.product_detail}
                </p>
                <p>
                  <span>Highlight: </span>
                  {itemDatail.product_highlight}
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ProductMngmt;
