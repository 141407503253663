import React, { useState, useEffect } from "react";
import FolderImg from "../assets/Folder-pana.png";
import {
  handleError,
  check_vaild_save,
  combiled_form_data,
  handleAphabetswithhashChange,
  handleIaphabetnumberChange,
  handleAphabetsChange,
  handleSuccessSession,
  handleURLChange,
  handleEmailChange,
  handleNumbersChange,
} from "../CommonJquery/CommonJquery.js";

import {
  server_post_data,
  get_category_packege,
  add_business_information,
} from "../ServiceConnection/serviceconnection.js";
import Loader from "../Components/Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import { AddImage_text } from "../CommonJquery/WebsiteText.js";
import Header from "./Header.js";
import { Link } from "react-router-dom";
function AddBusiness() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);

  const [selectedCategory, setselectedCategory] = useState("Catrgory");
  const [selectedPackage, setselectedPackage] = useState("Package");

  const handleSelect = (event) => {
    setselectedCategory(event.target.value); // Update the selected value when an option is selected
  };
  const handleSelect1 = (event) => {
    setselectedPackage(event.target.value); // Update the selected value when an option is selected
  };
  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get("", "", flag, call_id);
  }, []);

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    await server_post_data(get_category_packege, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          console.log(Response.data.message.Category_data);
          seteditStaffData(Response.data.message);
          if (Response.data.message.Category_data.length > 0) {
            setselectedCategory(Response.data.message.Category_data[0]);
          }
          if (Response.data.message.Package_data.length > 0) {
            setselectedPackage(
              Response.data.message.Package_data[0].primary_id
            );
          }
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("business_category", selectedCategory);
      fd_from.append("doctor_image_dd", "");
      fd_from.append("code_for", "+91");
      fd_from.append("package_id", selectedPackage);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          console.log(Response.data.message);
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "view_business_list");
          }
        })
        .catch((error) => {
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size < 200 * 1024) {
        // 200KB in bytes
        handleError("File size is below the minimum limit (200KB).");
        return;
      }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        handleError("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      handleError("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };

  useEffect(() => {
    const input = document.getElementById("searchInput");
    const autocomplete = new window.google.maps.places.Autocomplete(input);

    autocomplete.addListener("place_changed", function () {
      const place = autocomplete.getPlace();

      let full_address = place.address_components;
      let formatted_address = place.formatted_address;
      let geometry_data = place.geometry;
      let length_data = place.address_components.length;
      let citys = "";
      let state = "";
      let country = "";
      let tehsil = "";
      let postal_code = "";
      for (let i = 0; i < length_data; i++) {
        if (full_address[i].types[0] === "administrative_area_level_1") {
          state = full_address[i].long_name;
        } else if (full_address[i].types[0] === "country") {
          country = full_address[i].long_name;
        } else if (full_address[i].types[0] === "administrative_area_level_2") {
          citys = full_address[i].long_name;
        } else if (full_address[i].types[0] === "locality") {
          tehsil = full_address[i].long_name;
        } else if (full_address[i].types[0] === "postal_code") {
          postal_code = full_address[i].long_name;
        }
      }
      if (tehsil !== "") {
        citys = tehsil;
      }
      document.getElementById("searchInput").value = formatted_address;
      document.getElementById("business_city").value = citys;
      document.getElementById("business_state").value = state;
      document.getElementById("business_country").value = country;
      document.getElementById("business_pincode").value = postal_code;
      document.getElementById("business_longitude").value =
        geometry_data.location.lng();
      document.getElementById("business_latitude").value =
        geometry_data.location.lat();
    });
  }, []);

  return (
    <div className="addImage ">
      <Header />
      {showLoaderAdmin && <Loader />}

      <div className="pageCntn_head" style={{ top: "0px" }}>
        <div className="flexCOntauiner">
          <Link to="/view_business_list">
            <div className="pageNameDiv">
              <p>Business Management</p>
              <img src={GreyArrow} alt="Shopup Admin" />
            </div>
          </Link>
          <div className="pageNameDiv px-0">
            <p>Add Business</p>
            <img src={GreyArrow} alt="Shopup Admin" />
          </div>
        </div>
      </div>

      <div className="d-flex">
        <div className="col-md-11 m-auto">
          <form id="addNewStaff">
            <div className="event-box blog-data">
              <div className="form-group seoAddForm">
                {/*add image */}

                {/* add image end */}
                <div className="row m-0">
                  <div className="col-md-2 mt-2">
                    <div className="addImgform_Container">
                      {dynaicimage && dynaicimage.doctor_image_show ? (
                        <img
                          src={dynaicimage.doctor_image_show}
                          onError={(e) => (e.target.src = FolderImg)}
                          alt="Shopup Admin"
                        />
                      ) : (
                        <img src={FolderImg} alt="Shopup Admin" />
                      )}

                      <div className="imgInputCotnainer">
                        <p className="drop-img mt-2">
                          {" "}
                          Drop Your Logo Here<br></br>
                          <span className="colorred imageSizeText">
                            Size 600*600 max (500 KB)
                          </span>
                        </p>
                        <input
                          type="file"
                          name="doctor_image"
                          onChange={handleFileChangedynamic("doctor_image")}
                          className=" form-control"
                          accept=".jpg,.jpeg,.png"
                          hidden
                          id="imgInput"
                        />
                        {dynaicimage && dynaicimage.doctor_image_show ? (
                          <label htmlFor="imgInput">Change Logo </label>
                        ) : (
                          <label htmlFor="imgInput"> Choose Logo </label>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-10">
                    <div className="row">
                      <div className="col-md-4 mt-2">
                        <label className="no_prsnl_id">
                          Owner Name <span className="colorred">*</span>
                        </label>
                        <input
                          type="text"
                          name="owner_name"
                          id="owner_name"
                          onInput={handleAphabetsChange}
                          maxLength={100}
                          className="form-control trio_mandatory"
                          placeholder="Enter Owner Name*"
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="col-md-4 mt-2">
                        <label className="no_prsnl_id">
                          Owner Mobile No <span className="colorred">*</span>
                        </label>
                        <input
                          type="text"
                          name="owner_mobile_no"
                          id="owner_mobile_no"
                          onInput={handleNumbersChange}
                          maxLength={10}
                          className="form-control trio_mandatory"
                          placeholder="Enter Owner Mobile No"
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="col-md-4 mt-2">
                        <label className="no_prsnl_id">
                          Owner Email ID <span className="colorred">*</span>
                        </label>
                        <input
                          type="text"
                          name="owner_email_id"
                          id="owner_email_id"
                          onInput={handleEmailChange}
                          maxLength={100}
                          className="form-control "
                          placeholder="Enter Owner Email ID"
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="col-md-4 mt-2">
                        <label className="no_prsnl_id">
                          What's your business name?{" "}
                          <span className="colorred">*</span>
                        </label>
                        <input
                          type="text"
                          name="business_name"
                          id="business_name"
                          onInput={handleAphabetsChange}
                          maxLength={100}
                          className="form-control trio_mandatory"
                          placeholder="Enter business (registered or legal) name*"
                        />
                        <span className="condition_error"></span>
                      </div>
                      <div className="col-md-4">
                        <div className="inpContainer inpContainerSeo mt-2">
                          <label className="no_prsnl_id">
                            What's your GSTIN / Tax Code?{" "}
                          </label>
                          <input
                            type="text"
                            name="gstin_code"
                            id="gstin_code"
                            onInput={handleIaphabetnumberChange}
                            maxLength={30}
                            className="form-control "
                            placeholder="Enter GSTIN / Tax Code"
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4 bottomAlgin">
                        <label className="no_prsnl_id">
                          Package <span className="colorred">*</span>
                        </label>
                        <div className="inputDiv2">
                          <select id="selectFilter" onChange={handleSelect1}>
                            {editStaffData &&
                            editStaffData.Package_data &&
                            editStaffData.Package_data.length > 0 ? (
                              editStaffData.Package_data.map((option) => (
                                <option
                                  key={option.primary_id}
                                  value={option.primary_id}
                                >
                                  {option.package_name}
                                </option>
                              ))
                            ) : (
                              <option value="">No packages available</option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-4 bottomAlgin">
                        <label className="no_prsnl_id">
                          Category <span className="colorred">*</span>
                        </label>
                        <div className="inputDiv2">
                          <select id="selectFilter2" onChange={handleSelect}>
                            {editStaffData &&
                            editStaffData.Category_data &&
                            editStaffData.Category_data.length > 0 ? (
                              editStaffData.Category_data.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))
                            ) : (
                              <option value="">No Category available</option>
                            )}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <div className="inpContainer inpContainerSeo mt-2">
                          <label className="no_prsnl_id">
                            Business Address <span className="colorred">*</span>
                          </label>
                          <input
                            type="text"
                            name="address"
                            id="address"
                            onInput={handleIaphabetnumberChange}
                            className="form-control trio_mandatory"
                            placeholder="Enter Business Address"
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="inpContainer inpContainerSeo mt-2">
                          <label className="no_prsnl_id">
                            Application Name <span className="colorred">*</span>
                          </label>
                          <input
                            type="text"
                            name="application_name"
                            id="application_name"
                            onInput={handleAphabetsChange}
                            className="form-control trio_mandatory"
                            placeholder="Enter Application Name"
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="inpContainer mt-2">
                          <label className="no_prsnl_id">
                            Theme Color <span className="colorred">*</span>
                          </label>
                          <input
                            type="color"
                            name="app_color"
                            id="app_color"
                            className="form-control trio_mandatory"
                            placeholder="Enter Theme Color"
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="inpContainer inpContainerSeo mt-2">
                          <label className="no_prsnl_id">
                            Domain Name <span className="colorred">*</span>
                          </label>
                          <input
                            type="text"
                            name="temp_subdomain"
                            id="temp_subdomain"
                            onInput={handleURLChange}
                            className="form-control trio_mandatory"
                            placeholder="Enter Domain Name"
                          />
                          <span className="condition_error"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row m-0">
                  <div className="col-md-8">
                    <div className="inpContainer inpContainerSeo mt-2">
                      <label className="no_prsnl_id">
                        Map Address <span className="colorred">*</span>
                      </label>
                      <input
                        type="text"
                        name="business_map_address"
                        id="searchInput"
                        className="form-control trio_mandatory"
                        placeholder="Enter Map Address*"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-md-6 hidden">
                    <div className="inpContainer inpContainerSeo mt-2">
                      <label className="no_prsnl_id">
                        City <span className="colorred">*</span>
                      </label>
                      <input
                        type="text"
                        name="business_city"
                        id="business_city"
                        onInput={handleAphabetswithhashChange}
                        className="form-control trio_mandatory"
                        placeholder="Enter City*"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-md-4 ">
                    <div className="inpContainer inpContainerSeo mt-2">
                      <label className="no_prsnl_id">
                        Pin code <span className="colorred">*</span>
                      </label>
                      <input
                        type="text"
                        name="business_pincode"
                        id="business_pincode"
                        onInput={handleNumbersChange}
                        className="form-control trio_mandatory"
                        placeholder="Enter Pin code*"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-md-6 hidden">
                    <div className="inpContainer inpContainerSeo mt-2">
                      <label className="no_prsnl_id">
                        State <span className="colorred">*</span>
                      </label>
                      <input
                        type="text"
                        name="business_state"
                        id="business_state"
                        className="form-control trio_mandatory"
                        placeholder="Enter State Name*"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-md-6 hidden">
                    <div className="inpContainer inpContainerSeo mt-2">
                      <label className="no_prsnl_id">
                        Country <span className="colorred">*</span>
                      </label>
                      <input
                        type="text"
                        name="business_country"
                        id="business_country"
                        className="form-control trio_mandatory"
                        placeholder="Enter Country Name*"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="col-md-6 hidden">
                    <div className="inpContainer inpContainerSeo mt-2">
                      <label className="no_prsnl_id">
                        Business Latitude <span className="colorred">*</span>
                      </label>
                      <input
                        type="text"
                        name="business_latitude"
                        id="business_latitude"
                        className="form-control trio_mandatory"
                        placeholder="Enter Latitude code*"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>
                  <div className="col-md-6 hidden">
                    <div className="inpContainer inpContainerSeo mt-2">
                      <label className="no_prsnl_id">
                        Business Longitude <span className="colorred">*</span>
                      </label>
                      <input
                        type="text"
                        name="business_longitude"
                        id="business_longitude"
                        className="form-control trio_mandatory"
                        placeholder="Enter Longitude code*"
                      />
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="inpContainer mt-2">
                      <label className="no_prsnl_id">
                        Description <span className="colorred">*</span>
                      </label>
                      <textarea
                        type="text"
                        name="business_description"
                        id="business_description"
                        onInput={handleIaphabetnumberChange}
                        minLength={3}
                        maxLength={300}
                        className="textareBlogs mt-0 form-control trio_mandatory"
                        placeholder="Enter Description*"
                      ></textarea>
                      <span className="condition_error"></span>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="addBlogBtnDiv">
                      <button
                        type="button"
                        onClick={() =>
                          handleSaveChangesdynamic(
                            "addNewStaff",
                            add_business_information
                          )
                        }
                        className="btn btn-secondary mb-3 save-cap-btn"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddBusiness;
