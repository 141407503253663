import React, { useState, useEffect } from "react";
import "./Css/StaffRights.css";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FolderImg from "../assets/Folder-pana.png";
import FilterIcon from "../assets/filterIcon.png";
import {
  CreateStaffRightText,
  EditStaffPage,
} from "../CommonJquery/WebsiteText.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
  empty_form,
  handleIaphabetnumberChange,
  handleLinkClick,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  get_all_admin_category,
  add_category_super_admin,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
function Add_Category() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedValue, setSelectedValue] = useState("0");
  const [StaffImageLinkData1, setsStaffImageLinkData1] = useState("");
  const [StaffImageLinkData2, setsStaffImageLinkData2] = useState("");
  const [editOldImageData, seteditOldImageData] = useState([]);
  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    if (file && file.type.startsWith("image/")) {
      // Validate file size
      if (file.size < 200 * 1024) {
        // 200KB in bytes
        handleError("File size is below the minimum limit (200KB).");
        return;
      }

      if (file.size > 500 * 1024) {
        // 500KB in bytes
        handleError("File size exceeds the maximum limit (500KB).");
        return;
      }

      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        setDynaicimage((prevImages) => ({
          ...prevImages,
          [keyname]: file,
          [new_file_name]: reader.result,
        }));
      };

      reader.readAsDataURL(file);
    } else {
      handleError("Please select a valid image file.");
      event.target.value = ""; // Clear the file input
    }
  };
  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("main_category_id", selectedValue);
      fd_from.append("main_id", editorDataMainID);
      fd_from.append("old_image_1", editOldImageData);
      fd_from.append("old_image_2", StaffImageLinkData1);
      fd_from.append("old_image_3", StaffImageLinkData2);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/View_Category_list");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    const url = currentUrl;
    const parts = url.split("/");
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    fd.append("call_id", call_id);
    fd.append("flag", 4);
    await server_post_data(get_all_admin_category, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setOptions(Response.data.message.data_categoryInformation);
          if (Response.data.message.data_categoryInformation_main.length > 0) {
            setEditorDatMainID(
              Response.data.message.data_categoryInformation_main[0].primary_id
            );
            seteditStaffData(
              Response.data.message.data_categoryInformation_main[0]
            );
            seteditOldImageData(
              Response.data.message.data_categoryInformation_main[0]
                .category_image
            );
            setsStaffImageLinkData1(
              Response.data.message.data_categoryInformation_main[0]
                .category_image1
            );
            setsStaffImageLinkData2(
              Response.data.message.data_categoryInformation_main[0]
                .category_image2
            );
            setDynaicimage({
              doctor_image_show:
                APL_LINK +
                Response.data.message.data_imagelink_cate +
                Response.data.message.data_categoryInformation_main[0]
                  .category_image,
            });
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };
  // console.log(options);
  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="flexCOntauiner">
                <div className="pageNameDiv">
                  <p onClick={() => handleLinkClick("View_Category_list")}>
                    <p>View Category</p>
                  </p>
                  <img src={GreyArrow} alt="Shopup Admin" />
                </div>
                <div className="pageNameDiv px-0">
                  <p>
                    <p>
                      {editorDataMainID !== "0"
                        ? "Edit Category"
                        : "Add Category"}
                    </p>
                  </p>
                  <img src={GreyArrow} alt="Shopup Admin" />
                </div>
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="col-xl-12 col-lg-12 ">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0"></div>
                          <div className={`row `}>
                            <div className={`col-md-4 d-flex mt-3 `}>
                              <div className="flexColum inpContainer w-100">
                                <label className="no_prsnl_id">
                                  Category Image<br></br>
                                  <span className="colorred imageSizeText">
                                    Size 600*600 max (500 KB)
                                  </span>
                                </label>
                                <div className="text-center w-100">
                                  {dynaicimage &&
                                  dynaicimage.doctor_image_show ? (
                                    <img
                                      src={dynaicimage.doctor_image_show}
                                      onError={(e) =>
                                        (e.target.src = FolderImg)
                                      }
                                      alt="Shopup Admin"
                                      className="chooseImgD"
                                    />
                                  ) : (
                                    <img
                                      className="chooseImgD"
                                      src={FolderImg}
                                      alt="Shopup Admin"
                                    />
                                  )}
                                </div>
                                <input
                                  type="file"
                                  name="doctor_image"
                                  onChange={handleFileChangedynamic(
                                    "doctor_image"
                                  )}
                                  className={`${
                                    dynaicimage && dynaicimage.doctor_image_show
                                      ? ""
                                      : "trio_mandatory"
                                  } form-control`}
                                  accept=".jpg,.jpeg,.png"
                                  hidden
                                  id="imgInput"
                                />
                                <label
                                  className="imgInpBtn m-auto"
                                  htmlFor="imgInput"
                                >
                                  {dynaicimage && dynaicimage.doctor_image_show
                                    ? "Change Image"
                                    : " Choose Image"}
                                </label>
                                <span className="condition_error"></span>
                              </div>
                            </div>
                            <div className="col-md-8  mt-3">
                              <div className="inputDiv2 mb-3">
                                <label
                                  className="labelPointer"
                                  htmlFor="selectFilter"
                                >
                                  <img src={FilterIcon} alt="Shopup Admin" />
                                </label>
                                <select
                                  id="selectFilter"
                                  onChange={handleSelect}
                                >
                                  <option value={0}>Parent</option>
                                  {options.length > 0 &&
                                    options.map((option) => {
                                      if (option.main_category_id == 0) {
                                        return (
                                          <option
                                            key={option.primary_id}
                                            value={option.primary_id}
                                          >
                                            {option.category_name}
                                          </option>
                                        );
                                      }
                                    })}
                                </select>
                              </div>
                              <div className=" inpContainer">
                                <label className="no_prsnl_id">
                                  Category Name
                                  <span className="colorred">*</span>
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="category_name"
                                    name="category_name"
                                    minLength={4}
                                    maxLength={100}
                                    className={`  trio_mandatory  input_field_customPadding form-control`}
                                    placeholder="Category Name"
                                    onInput={handleIaphabetnumberChange}
                                    defaultValue={
                                      editStaffData.category_name || ""
                                    }
                                  />
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                              <div className="mb-0 inpContainer">
                                <label className="no_prsnl_id">
                                  Category Details
                                  <span className="colorred">*</span>
                                </label>
                                <div>
                                  <input
                                    type="text"
                                    id="category_details"
                                    name="category_details"
                                    maxLength={300}
                                    minLength={4}
                                    className={`  trio_mandatory  input_field_customPadding form-control`}
                                    placeholder="Category Details"
                                    onInput={handleIaphabetnumberChange}
                                    defaultValue={
                                      editStaffData.category_details || ""
                                    }
                                  />
                                  <span className="condition_error"></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="createRightBtnDiv">
                            <button
                              className="btn btn-primary Create_Right_Btn btnSave"
                              type="button"
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  "createRightsForm",
                                  add_category_super_admin
                                )
                              }
                            >
                              {location.pathname.includes("/edit_category")
                                ? EditStaffPage.save_text
                                : CreateStaffRightText.Create_text}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_Category;
