import React, { useState, useEffect } from "react";
import "./Css/StaffRights.css";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import {
  CreateStaffRightText,
  EditStaffPage,
} from "../CommonJquery/WebsiteText.js";
import {
  check_vaild_save,
  combiled_form_data,
  handleSuccessSession,
  handleError,
  empty_form,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleAphabetsChange,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  add_product_webapp_master,
  get_all_cate_product,
} from "../ServiceConnection/serviceconnection.js";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import Select from "react-select";
function Edit_Product() {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editorDataMainID, setEditorDatMainID] = useState("0");
  const [editStaffData, seteditStaffData] = useState([]);
  const [groupedOptions, setgroupedOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const groupStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const groupBadgeStyles = {
    backgroundColor: "#EBECF0",
    borderRadius: "2em",
    color: "#172B4D",
    display: "inline-block",
    fontSize: 12,
    fontWeight: "normal",
    lineHeight: "1",
    minWidth: 1,
    padding: "0.16666666666667em 0.5em",
    textAlign: "center",
  };

  const formatGroupLabel = (data) => (
    <div style={groupStyles}>
      <span>{data.label}</span>
      <span style={groupBadgeStyles}>{data.options.length}</span>
    </div>
  );

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, null);
      fd_from.append("product_id", editorDataMainID);
      fd_from.append("total_image_loop", 0);
      fd_from.append("image_product", 1);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            handleSuccessSession(Response.data.message, "/View_Product_list");
            empty_form(form_data);
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    const url = currentUrl;
    const parts = url.split("/");
    let call_id = "0";
    if (parts.length !== 1) {
      call_id = parts[1];
    }
    fd.append("call_id", call_id);

    fd.append("flag", 1);
    await server_post_data(get_all_cate_product, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setgroupedOptions(Response.data.message.data_categoryInformation);
          if (Response.data.message.product_data.length > 0) {
            setEditorDatMainID(
              Response.data.message.product_data[0].primary_id
            );
            seteditStaffData(Response.data.message.product_data[0]);
            const dataCategoryInformation =
              Response.data.message.data_categoryInformation;
            const productData = Response.data.message.product_data[0];
            let defaultOption;
            dataCategoryInformation.some((data) => {
              const defaultOptionCheck = data.options.find(
                (option) =>
                  option.primary_id === productData.sub_category_id &&
                  data.primary_id === productData.main_category_id
              );
              if (defaultOptionCheck) {
                defaultOption = defaultOptionCheck;
                return true; // Exit the loop early
              }
              return false;
            });
            setSelectedOption(defaultOption);
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head container-lg">
              <div className="pageNameDiv">
                <p>
                  <p>
                    {" "}
                    {location.pathname.includes("/edit_product")
                      ? EditStaffPage.save_text
                      : CreateStaffRightText.Create_text}{" "}
                    Product
                  </p>
                </p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
            </div>

            <div className="page_body">
              <div className="create_staffRights container-lg">
                <form className="createRightsForm" id="createRightsForm">
                  <div className="row m-0">
                    <div className="col-xl-12 col-lg-12 ">
                      <div className="personalForm">
                        <div className="staffForm_container">
                          <div className="row m-0">
                            <div className={`row `}>
                              <div className={`col-md-3 `}>
                                <div className="inpContainer">
                                  <div className="display_grid">
                                    <label className="no_prsnl_id">
                                      Category Name
                                      <span className="colorred">*</span>
                                    </label>
                                    <Select
                                      options={groupedOptions}
                                      name="select_category_id"
                                      formatGroupLabel={formatGroupLabel}
                                      value={selectedOption}
                                    />

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Product Name
                                    <span className="colorred">*</span>
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      id="product_name"
                                      name="product_name"
                                      minLength={4}
                                      maxLength={100}
                                      className={`  trio_mandatory  input_field_customPadding form-control`}
                                      placeholder="Product Name"
                                      onInput={handleIaphabetnumberChange}
                                      defaultValue={
                                        editStaffData.product_name || ""
                                      }
                                    />

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Product Qty
                                    <span className="colorred">*</span>
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      id="product_qty"
                                      name="product_qty"
                                      maxLength={10}
                                      className={`  trio_mandatory  input_field_customPadding form-control`}
                                      placeholder="Product Qty"
                                      onInput={handleNumbersChange}
                                      defaultValue={
                                        editStaffData.product_qty || ""
                                      }
                                    />

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-3`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Product Qty Unit Ex. (GM, KG ,ML)
                                    <span className="colorred">*</span>
                                  </label>
                                  <div>
                                    <input
                                      type="text"
                                      id="product_qty_unit"
                                      name="product_qty_unit"
                                      maxLength={10}
                                      className={`  trio_mandatory  input_field_customPadding form-control`}
                                      placeholder="Product Qty Unit Name"
                                      onInput={handleAphabetsChange}
                                      defaultValue={
                                        editStaffData.product_qty_unit || ""
                                      }
                                    />

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-6`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Product Details
                                    <span className="colorred">*</span>
                                  </label>
                                  <div>
                                    <textarea
                                      id="product_detail"
                                      name="product_detail"
                                      maxLength={300}
                                      minLength={4}
                                      className={`  trio_mandatory  input_field_customPadding form-control`}
                                      placeholder="Product Details"
                                      onInput={handleIaphabetnumberChange}
                                      defaultValue={
                                        editStaffData.product_detail || ""
                                      }
                                    />

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                              <div className={`col-md-6`}>
                                <div className="inpContainer">
                                  <label className="no_prsnl_id">
                                    Product Highlight
                                  </label>
                                  <div>
                                    <textarea
                                      type="text"
                                      id="product_highlight"
                                      name="product_highlight"
                                      maxLength={300}
                                      minLength={4}
                                      className={`    input_field_customPadding form-control`}
                                      placeholder="Product Highlight"
                                      onInput={handleIaphabetnumberChange}
                                      defaultValue={
                                        editStaffData.product_highlight || ""
                                      }
                                    />

                                    <span className="condition_error"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="createRightBtnDiv">
                            <button
                              className="btn btn-primary Create_Right_Btn btnSave"
                              type="button"
                              onClick={() =>
                                handleSaveChangesdynamic(
                                  "createRightsForm",
                                  add_product_webapp_master
                                )
                              }
                            >
                              {location.pathname.includes("/edit_product")
                                ? EditStaffPage.save_text
                                : CreateStaffRightText.Create_text}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Edit_Product;
