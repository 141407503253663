import React, { useState, useEffect } from "react";
import Header from "./Header.js";
import Loader from "./Loader.js";
import GreyArrow from "../assets/greyLeftAr.png";
import FilterIcon from "../assets/filterIcon.png";
import Search from "../assets/search.png";
import GuestIcon from "../assets/guestIcon.png";
import { Modal, Button } from "react-bootstrap";
import {
  options_search,
  GuestManagementPageText,
} from "../CommonJquery/WebsiteText.js";
import {
  server_post_data,
  get_all_businessinformation,
  update_apk_link_data,
  update_vendor_active_status,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleError,
  computeTodayDate,
  handleIaphabetnumberChange,
  handleAphabetsChange,
  handleEmailChange,
  handleNumbersChange,
  computeplussevendays,
  handleURLChange,
} from "../CommonJquery/CommonJquery.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleSuccess,
} from "../CommonJquery/CommonJquery.js";
import { Link } from "react-router-dom";

function BusinessListMngmt() {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [editStaffData, seteditStaffData] = useState([]);
  // Set initial state using useState hook
  const [startDate, setStartDate] = useState(computeplussevendays());
  const [endDate, setEndDate] = useState(computeTodayDate());
  const [SelectedData, setSelectedData] = useState([]);
  const [searchfilter, setSearchFilter] = useState("");
  const [filteredData, setfilteredData] = useState([]);
  const [dynaicimage, setDynaicimage] = useState(null);
  const [selectedValue, setSelectedValue] = useState("Name"); // State to hold the selected value
  const [StaffImageLinkData, setsStaffImageLinkData] = useState("");
  const [StaffImageLinkData2, setsStaffImageLinkData2] = useState("");

  const handleSelect = (event) => {
    setSelectedValue(event.target.value); // Update the selected value when an option is selected
  };

  useEffect(() => {
    const flag = "1";
    let call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  }, []);

  const search_data = () => {
    const flag = "2";
    const call_id = "0";
    master_data_get(startDate, endDate, flag, call_id);
  };

  const master_data_get = async (start_date, end_date, flag, call_id) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("start_date", start_date);
    fd.append("end_date", end_date);
    fd.append("flag", flag);
    fd.append("call_id", call_id);
    fd.append("search_option", selectedValue);
    fd.append("search_data", document.getElementById("search_data").value);
    await server_post_data(get_all_businessinformation, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setsStaffImageLinkData(
            APL_LINK + Response.data.message.data_image_link
          );
          setsStaffImageLinkData2(
            APL_LINK + Response.data.message.data_logo_link
          );
          seteditStaffData(Response.data.message.data_customerInformation);
          setfilteredData(Response.data.message.data_customerInformation);
        }

        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSearch = (event) => {
    const searchValue = event.target.value;
    setSearchFilter(searchValue);

    // Filter table data based on search value
    const filteredDatashow = editStaffData.filter((row) => {
      return Object.values(row).some((value) => {
        if (value !== null && value !== undefined) {
          // Add null check here
          return (
            typeof value === "string"
              ? value.toLowerCase()
              : value.toString().toLowerCase()
          ).includes(searchValue.toLowerCase());
        }
        return false;
      });
    });

    setfilteredData(filteredDatashow);
  };
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const openModal = (guestName, index) => {
    setSelectedData(guestName);
    setShowModal(true);
  };

  const openModal1 = (guestName) => {
    setSelectedData(guestName);

    setShowModal1(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setShowModal1(false);
    setShowModal2(false);
  };
  const [showModal2, setShowModal2] = useState(false);
  const [clickedButton, setClickedButton] = useState(null);
  const [selectedGuestIndex, setSelectedGuestIndex] = useState(null);
  const openModal_active = (guestName, index) => {
    let tesr_show = "  ";
    if (guestName.active_status === 1) {
      tesr_show = " Not ";
    }
    setShowModal2(true);
    setSelectedData(guestName);
    setClickedButton(guestName.business_name + tesr_show);
    setSelectedGuestIndex(index);
  };

  const confirmVIP = (index, flag) => {
    let allow_access_data = "0";
    if (SelectedData.active_status === 0) {
      allow_access_data = "1";
    }
    master_data_action_update(SelectedData.primary_id, allow_access_data, flag);
  };

  const master_data_action_update = async (call_id, for_status_final, flag) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();

    fd.append("id_for_delete", call_id);
    fd.append("flag_for", flag);
    fd.append("for_status_final", for_status_final);
    await server_post_data(update_vendor_active_status, fd)
      .then((Response) => {
        setshowLoaderAdmin(false);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          closeModal();
          master_data_get("", "", "1", "0");
        }
      })
      .catch((error) => {
        console.log(error);
        handleError("network");
        setshowLoaderAdmin(false);
      });
  };

  const handleSaveChangesdynamic = async (form_data, url_for_save) => {
    let vaild_data = check_vaild_save(form_data);

    if (vaild_data) {
      setshowLoaderAdmin(true);
      let fd_from = combiled_form_data(form_data, dynaicimage);
      fd_from.append("business_id", SelectedData.primary_id);
      await server_post_data(url_for_save, fd_from)
        .then((Response) => {
          setshowLoaderAdmin(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            master_data_get("", "", "1", "0");
            handleSuccess(Response.data.message);
            empty_form(form_data);
            closeModal();
          }
        })
        .catch((error) => {
          console.log(error);
          setshowLoaderAdmin(false);
        });
    }
  };

  const handleFileChangedynamic = (keyname) => (event) => {
    const file = event.target.files[0];

    let new_file_name = keyname + "_show";
    if (!file) {
      return;
    }

    const reader = new FileReader();

    reader.onload = () => {
      // const img = new Image();
      // img.src = reader.result;

      setDynaicimage((prevImages) => ({
        ...prevImages,
        [keyname]: file,
        [new_file_name]: reader.result,
      }));
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="dashboard">
      {showLoaderAdmin && <Loader />}

      <div className="dashboard_container">
        <div className="page_content">
          <div className="page_content_container">
            <Header />
            <div className="pageCntn_head">
              <div className="pageNameDiv">
                <p>{GuestManagementPageText.Business_Management}</p>
                <img src={GreyArrow} alt="Shopup Admin" />
              </div>
              <div className="col-xl-2 col-sm-4 bottomAlgin">
                <Link to={`/Add_Business`}>
                  <button type="button" className="btnAddStaff add_search">
                    Add New Business
                  </button>
                </Link>
              </div>
            </div>

            <div className="page_body">
              <div className="viewStaff">
                <div className="viewStaff_head">
                  <div className="row m-0">
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {GuestManagementPageText.Start_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="startDate"
                          type="date"
                          placeholder={GuestManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2 col-sm-4 bottomAlgin">
                      <label className="labelView">
                        {GuestManagementPageText.End_Date}
                      </label>
                      <div className="person__calenderFrame_image image_icon_position1 ">
                        <input
                          id="endDate"
                          type="date"
                          placeholder={GuestManagementPageText.D_O_B}
                          className="form-control  input_field_custom4"
                          defaultValue={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                          max={computeTodayDate()}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-sm-4 bottomAlgin">
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <select id="selectFilter" onChange={handleSelect}>
                          {options_search.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="col-lg-5 col-sm-8 bottomAlgin">
                      <div className="inputDiv2">
                        <img src={Search} alt="Shopup Admin" />
                        <input
                          type="text"
                          id="search_data"
                          onInput={(e) => {
                            if (selectedValue === options_search[0].value) {
                              handleAphabetsChange(e);
                            } else if (
                              selectedValue === options_search[1].value
                            ) {
                              handleEmailChange(e);
                            } else if (
                              selectedValue === options_search[2].value
                            ) {
                              handleNumbersChange(e);
                            }
                          }}
                          placeholder={GuestManagementPageText.Search_Guest}
                        />
                        <button
                          type="button"
                          className="btnSearch"
                          onClick={() => search_data()}
                        >
                          {GuestManagementPageText.Search_text}
                        </button>
                      </div>
                    </div>

                    <div
                      className="col-xl-3 col-md-4 mt-3"
                      style={{ marginLeft: "auto", marginRight: "0" }}
                    >
                      <div className="inputDiv2">
                        <label className="labelPointer" htmlFor="selectFilter">
                          <img src={FilterIcon} alt="Shopup Admin" />
                        </label>
                        <input
                          type="text"
                          placeholder={GuestManagementPageText.Guest_Filter}
                          value={searchfilter}
                          onInput={handleIaphabetnumberChange}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="viewGuest_table ">
                  <div className="viewGuest_table_container ">
                    <div className="row m-0">
                      <div className="col-md-12">
                        <table className="table">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div className="theadStyle">No</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle imgThead  ">
                                  <img src={GuestIcon} alt="Shopup Admin" />
                                  <span>Owner Name</span>
                                </div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Business Name</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Package Name</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">App Name</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Status</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Details</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">Upload App</div>
                              </th>
                              <th scope="col">
                                <div className="theadStyle">
                                  Payment Details
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="tboday">
                            {filteredData.map((option, index) => (
                              <React.Fragment key={index}>
                                <tr className="tableRow tbodyStyle">
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {index + 1}
                                    </div>
                                  </td>
                                  <td className="th1 tabledata">
                                    <div className="guest_incenter borderLeftRadius">
                                      <img
                                        src={
                                          StaffImageLinkData2 +
                                          option.application_logo
                                        }
                                        onError={(e) =>
                                          (e.target.src = GuestIcon)
                                        }
                                        alt="Staff avatar"
                                        className="staff-avatar image_show2010"
                                      />
                                      {option.owner_name}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.business_name}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option?.package_details?.[0]?.[
                                        "package_name"
                                      ] ?? "Null"}
                                    </div>
                                  </td>
                                  <td className="tabledata">
                                    <div className="guest_incenter shadowOnlyBottom">
                                      {option.application_name}
                                    </div>
                                  </td>
                                  <td className="th2 tabledata">
                                    <div className="guest_incenterActions borderRightRadius">
                                      <button
                                        type="button"
                                        className={`markVip Mark_Vip ${
                                          option.active_status === 1
                                            ? "vipMarked"
                                            : "vipMarkedred"
                                        }`}
                                        onClick={() =>
                                          openModal_active(option, index)
                                        }
                                      >
                                        <p>
                                          {option.active_status === 0
                                            ? "Pending"
                                            : "Active"}
                                        </p>
                                      </button>
                                    </div>
                                  </td>
                                  <td className="th2 tabledata">
                                    <div className="guest_incenterActions borderRightRadius">
                                      <button
                                        type="button"
                                        className={`markVip Mark_Vip m-0
                                        ${
                                          option.active_status === 1
                                            ? "vipMarked"
                                            : ""
                                        }`}
                                        onClick={() => openModal(option, index)}
                                      >
                                        <p>See Details</p>
                                      </button>
                                    </div>
                                  </td>
                                  <td className="th2 tabledata">
                                    <div className="guest_incenterActions borderRightRadius">
                                      <button
                                        type="button"
                                        className={`markVip Mark_Vip m-0
                                        ${
                                          option.active_status === 1
                                            ? "vipMarked"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          openModal1(option, index)
                                        }
                                      >
                                        <p>Upload</p>
                                      </button>
                                    </div>
                                  </td>
                                  <td className="th2 tabledata">
                                    <div className="guest_incenterActions borderRightRadius">
                                      <Link
                                        to={`/Add_paymant/${option.primary_id}`}
                                      >
                                        <button
                                          type="button"
                                          className={`markVip Mark_Vip m-0`}
                                        >
                                          <p>Payment</p>
                                        </button>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                                <tr
                                  key={`spacer-${index}`}
                                  style={{ height: "1rem" }}
                                ></tr>
                              </React.Fragment>
                            ))}
                            {filteredData.length === 0 && (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="text_align_center border_1px"
                                >
                                  No Results Found
                                </td>{" "}
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="width100per">
            <div className="d-flex flex-row justify-content-between  font-15px  ">
              <p>Business Address</p>
              <p className="ms-3">{SelectedData.business_address}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Map Address</p>
              <p className="ms-3">{SelectedData.business_map_address}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Latitude</p>
              <p className="ms-3">{SelectedData.business_latitude}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Longitude</p>
              <p className="ms-3">{SelectedData.business_longitude}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Pin Code</p>
              <p className="ms-3">{SelectedData.business_pincode}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business City</p>
              <p className="ms-3">{SelectedData.business_city}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business State</p>
              <p className="ms-3">{SelectedData.business_state}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Country</p>
              <p className="ms-3">{SelectedData.business_country}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Description</p>
              <p className="ms-3">{SelectedData.business_description}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Category</p>
              <p className="ms-3">{SelectedData.business_category}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Owner Name</p>
              <p className="ms-3">{SelectedData.owner_name}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Owner Mobile No.</p>
              <p className="ms-3">{SelectedData.owner_mobile_no}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Owner Other Mobile No.</p>
              <p className="ms-3">{SelectedData.owner_other_no}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Owner Email Id</p>
              <p className="ms-3">{SelectedData.owner_email_id}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Owner Otp</p>
              <p className="ms-3">{SelectedData.owner_otp}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Registation No.</p>
              <p className="ms-3">{SelectedData.gstin_code}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Application Name</p>
              <p className="ms-3">{SelectedData.application_name}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Application Color</p>
              <p className="ms-3">{SelectedData.app_color}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Start Time</p>
              <p className="ms-3">{SelectedData.business_start_time}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business End Time</p>
              <p className="ms-3">{SelectedData.business_end_time}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business End Time</p>
              <p className="ms-3">{SelectedData.business_end_time}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Description</p>
              <p className="ms-3">{SelectedData.business_description}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Business Category</p>
              <p className="ms-3">{SelectedData.business_category}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Package Name</p>
              <p className="ms-3">
                {SelectedData.package_details &&
                SelectedData.package_details.length > 0
                  ? SelectedData.package_details[0].package_name
                  : undefined}
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Package Price</p>
              {SelectedData.package_details &&
              SelectedData.package_details.length > 0
                ? SelectedData.package_details[0].package_price
                : undefined}
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Total Days</p>

              {SelectedData.package_details &&
              SelectedData.package_details.length > 0
                ? SelectedData.package_details[0].total_days
                : undefined}
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Subscription Start Date</p>
              {SelectedData.business_package_details &&
              SelectedData.business_package_details.length > 0
                ? SelectedData.business_package_details[0]
                    .subscription_start_date
                : undefined}
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Subscription End Date</p>
              {SelectedData.business_package_details &&
              SelectedData.business_package_details.length > 0
                ? SelectedData.business_package_details[0].subscription_end_date
                : undefined}
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Payment Type</p>
              {SelectedData.business_package_details &&
              SelectedData.business_package_details.length > 0
                ? SelectedData.business_package_details[0].payment_type
                : undefined}
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Payment Transaction Id</p>
              {SelectedData.business_package_details &&
              SelectedData.business_package_details.length > 0
                ? SelectedData.business_package_details[0]
                    .payment_transaction_id
                : undefined}
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Update Date</p>
              <p className="ms-3">{SelectedData.update_date}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Last Order Date</p>
              <p className="ms-3">{SelectedData.last_order_date}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Taxes</p>
              <p className="ms-3">{SelectedData.taxes_per}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Packing Charge</p>
              <p className="ms-3">{SelectedData.packing_charges}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Delivary Charge</p>
              <p className="ms-3">{SelectedData.delivery_charges}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Max Distance</p>
              <p className="ms-3">{SelectedData.max_distance}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Total Orders</p>
              <p className="ms-3">{SelectedData.total_orders}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Total SMS Credit</p>
              <p className="ms-3">{SelectedData.total_sms_credit}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Instragram Link</p>
              <p className="ms-3">{SelectedData.instargram_link}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Facebook Link</p>
              <p className="ms-3">{SelectedData.facebook_link}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Twitter Link</p>
              <p className="ms-3">{SelectedData.twitter_link}</p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Domain</p>
              <p className="ms-3">
                <a href={SelectedData.temp_subdomain}>Website URL</a>
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Android Apk Link</p>
              <p className="ms-3">
                <a href={StaffImageLinkData + SelectedData.android_apk_link}>
                  Download App
                </a>
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Ios Apk Link</p>
              <p className="ms-3">
                <a href={StaffImageLinkData + SelectedData.ios_apk_link}>
                  Download App
                </a>
              </p>
            </div>
            <div className="d-flex flex-row justify-content-between  font-15px ">
              <p>Vendor Apk Link</p>
              <p className="ms-3">
                <a href={StaffImageLinkData + SelectedData.vendor_apk_link}>
                  Download App
                </a>
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="logoutYesBtn" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showModal1} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>
          <div>
            <form id="addNewStaff">
              <div className="addImgform_Container">
                <div className="imgInputCotnainer">
                  <p>
                    Upload Android Apk<span className="colorred">*</span>
                  </p>

                  <input
                    type="text"
                    name="app_name"
                    className="trio_mandatory form-control"
                    defaultValue={SelectedData.android_apk_name || ""}
                    placeholder="Enter Android Apk Name"
                    maxLength={100}
                  />
                  <input
                    type="file"
                    name="android_apk"
                    onChange={handleFileChangedynamic("android_apk")}
                    className="trio_mandatory form-control"
                    id="imgInput"
                  />
                  <span className="condition_error"></span>
                  <p>
                    Upload IOS Apk<span className="colorred">*</span>
                  </p>
                  <input
                    type="text"
                    name="ios_name"
                    className="trio_mandatory form-control"
                    defaultValue={SelectedData.ios_apk_name || ""}
                    placeholder="Enter Ios Apk Name"
                    maxLength={100}
                  />
                  <input
                    type="file"
                    name="ios_apk"
                    onChange={handleFileChangedynamic("ios_apk")}
                    className="trio_mandatory form-control"
                    id="imgInput"
                  />
                  <span className="condition_error"></span>
                  <p>
                    Upload Vendor Apk<span className="colorred">*</span>
                  </p>
                  <input
                    type="text"
                    className="trio_mandatory form-control"
                    defaultValue={SelectedData.vendor_apk_name || ""}
                    name="vendor_apk_name"
                    placeholder="Enter Vendor Apk Name"
                    maxLength={100}
                  />
                  <input
                    type="file"
                    name="vendor_apk"
                    onChange={handleFileChangedynamic("vendor_apk")}
                    className="trio_mandatory form-control"
                    id="imgInput"
                  />
                  <span className="condition_error"></span>
                  <p>
                    Website Link<span className="colorred">*</span>
                  </p>
                  <input
                    type="text"
                    name="web_link"
                    className="trio_mandatory form-control"
                    defaultValue={SelectedData.temp_subdomain || ""}
                    maxLength={100}
                    onInput={handleURLChange}
                  />
                  <span className="condition_error"></span>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            type="button"
            onClick={() =>
              handleSaveChangesdynamic("addNewStaff", update_apk_link_data)
            }
          >
            Submit
          </Button>
          <Button className="logoutYesBtn" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showModal2} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>
            Are you sure you want to mark{" "}
            <span className="color_primary">{clickedButton}</span> as Active
            Business?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="logoutYesBtn"
            onClick={() => confirmVIP(selectedGuestIndex, 1)}
          >
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BusinessListMngmt;
