import React, { useState, useEffect, useRef } from "react";
import "./Css/Header.css";
import "./Css/Loading.css";
import logicn from "../assets/profileseticn.svg";
import profileseticn from "../assets/logoutIcn.svg";
import { Modal, Button } from "react-bootstrap";
import DashboardImgBlack from "../assets/dashboarddd.svg";
import DashboardImgWhite from "../assets/dashboardddWhite.svg";
import AddIcon from "../assets/plusE.svg";
import AddIconWhite from "../assets/plusEWhite.svg";
import Walking from "../assets/walkMen.svg";
import WalkingWhite from "../assets/walkMenWhite.svg";
import Dummy from "../assets/DummyNew.png";
import Analytics from "../assets/download.svg";
import AnalyticsWhite from "../assets/downloadWhite.svg";
import OpenMenu from "../assets/menu_openWHite.svg";
import SearchIcon from "../assets/megnfineSearc.svg";
import DownArrow from "../assets/chevron-down-svgrepo-com.svg";
import SearchImg from "../assets/searchBlackHead.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  handleLinkClick,
  handleSuccess,
  inputdateformateChangeyear,
  handleError,
} from "../CommonJquery/CommonJquery.js";
import {
  server_post_data,
  get_all_searchmaster,
} from "../ServiceConnection/serviceconnection.js";
import {
  retrieveData,
  removeData,
} from "../LocalConnection/LocalConnection.js";
import { Link, useNavigate, useLocation } from "react-router-dom";
import SideBar from "./SideBar.js";
function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [options, setoptions] = useState([]);
  const admin_image = retrieveData("admin_image");
  const restaurant_name = retrieveData("admin_name");
  const designation_name = retrieveData("designation_name");

  useEffect(() => {
    const retrivemsg = retrieveData("session_msg");
    const retrievedDataFind = retrieveData("admin_name");
    if (
      retrievedDataFind === "null" ||
      retrievedDataFind === null ||
      retrievedDataFind === "1"
    ) {
      navigate("/");
    }
    if (
      retrivemsg !== "" &&
      retrivemsg !== null &&
      retrivemsg !== "0" &&
      retrivemsg !== "1"
    ) {
      handleSuccess(retrivemsg, 1);
    }
    checkTime();
  }, []);

  useEffect(() => {
    master_data_get();
  }, []);

  /////////////////////// Firebase Notification Start ///////////////////
  const [shownotification, setshownotification] = useState(false);

  // Reset shownotification after a certain period of time
  useEffect(() => {
    const resetNotification = () => {
      if (shownotification) {
        setTimeout(() => {
          setshownotification(false);
        }, 1000);
      }
    };

    resetNotification();
    return () => clearTimeout(resetNotification);
  }, [shownotification]);
  /////////////////////// Firebase Notification Close ///////////////////

  //sidebar open
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sideBarRef = useRef(null);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const checkTime = () => {
    try {
      let now = new Date();
      let hr = now.getHours();
      let min = now.getMinutes();
      let seconds = now.getSeconds();
      let hours = document.querySelector(".hours");
      let minutes = document.querySelector(".minutes");

      if (hr < 10) {
        hr = "0" + hr;
      }
      if (min < 10) {
        min = "0" + min;
      }
      if (hours && minutes) {
        hours.textContent = hr + " : ";
        minutes.textContent = min;
      }

      // Calculate remaining seconds until the next minute
      let remainingSeconds = 59 - seconds;

      if (Number(remainingSeconds) < 2) {
        remainingSeconds = 60;
      }

      // Set the interval based on remaining seconds
      setTimeout(checkTime, remainingSeconds * 1000);
    } catch (err) {
      console.log(err);
    }
  };
  const logoutpopup = () => {
    setShowModal(false);
    removeData();
    navigate("/");
  };

  const handleSearchButtonClick = () => {
    const storedSearchHistory = localStorage.getItem("searchHistory");
    if (storedSearchHistory) {
      const searchHistory = JSON.parse(storedSearchHistory);
      setSearchResults(searchHistory.slice(-5));
    } else {
      setSearchResults([]);
    }
    setIsSearchActive(true);
  };

  const master_data_get = async () => {
    const fd = new FormData();
    fd.append("flag", "1");
    fd.append("master_from", "0");
    await server_post_data(get_all_searchmaster, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setoptions(Response.data.message.data_search);
        }
      })
      .catch((error) => {
        handleError("network");
      });
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value.trim() === "") {
      setSearchResults([]);
    } else {
      const filteredResults = options.filter(
        (option) =>
          option.master_name.toLowerCase().includes(value.toLowerCase()) ||
          option.master_value
            .toLowerCase()
            .split(",") // Split the value string by commas
            .some((subValue) => subValue.trim().includes(value.toLowerCase())) // Check if any substring matches the search term
      );
      setSearchResults(filteredResults);
    }
    setIsSearchActive(true);
  };

  const handleSearchResultClick = (result) => {
    const storedSearchHistory = localStorage.getItem("searchHistory");
    let searchHistory = storedSearchHistory
      ? JSON.parse(storedSearchHistory)
      : [];
    const index = searchHistory.findIndex(
      (item) => item.master_url === result.master_url
    );
    if (index !== -1) {
      searchHistory.splice(index, 1);
    }
    searchHistory = [result, ...searchHistory.slice(0, 4)];

    localStorage.setItem("searchHistory", JSON.stringify(searchHistory));
    window.location.href = window.location.origin + "/" + result.master_url;
  };

  const headSearchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        headSearchRef.current &&
        !headSearchRef.current.contains(event.target)
      ) {
        setIsSearchActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headSearchRef]);

  /******* Upper Search Close**********/
  return (
    <div className="header">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid nav_container p-0">
          <div className="navbar-collapse" id="navbarNav">
            <span className="accesibilities">
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                htmlFor="top"
                title={`Open`}
                className={`menuOpenClose `}
                onClick={toggleSidebar}
                style={{ paddingLeft: "0" }}
              >
                <img src={OpenMenu} alt="Shopup Admin" />
              </div>
              <div className="accesibilitiesIcons">
                <Link
                  rel="noopener"
                  onClick={() => handleLinkClick("/Dashboard")}
                >
                  <button
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    htmlFor="top"
                    title={`Dashboard`}
                    className={` ${
                      location.pathname === "/Dashboard"
                        ? "selectedHeadBtn"
                        : ""
                    } `}
                  >
                    <img
                      src={` ${
                        location.pathname === "/Dashboard"
                          ? DashboardImgWhite
                          : DashboardImgBlack
                      } `}
                    />
                  </button>
                </Link>
              </div>
              <Link
                rel="noopener"
                onClick={() => handleLinkClick("/Add_Business")}
              >
                <button
                  className={` rsrvHeadBtn ${
                    location.pathname === "/Add_Business"
                      ? "selectedHeadBtn"
                      : ""
                  } `}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  htmlFor="top"
                  title={`Orders`}
                >
                  <img
                    src={` ${
                      location.pathname === "/Add_Business"
                        ? AddIconWhite
                        : AddIcon
                    } `}
                  />
                  <p
                    className={` ${
                      location.pathname === "/Add_Business"
                        ? "showOnActive"
                        : "hideAfter1150"
                    }`}
                  >
                    BUSINESS
                  </p>
                </button>
              </Link>
              <Link
                rel="noopener"
                onClick={() => handleLinkClick("/View_Product_list")}
              >
                <button
                  className={` rsrvHeadBtn ${
                    location.pathname === "/View_Product_list"
                      ? "selectedHeadBtn"
                      : ""
                  } `}
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  htmlFor="top"
                  title={`Product Management`}
                >
                  <img
                    src={` ${
                      location.pathname === "/View_Product_list"
                        ? WalkingWhite
                        : Walking
                    } `}
                  />
                  <p
                    className={` ${
                      location.pathname === "/View_Product_list"
                        ? "showOnActive"
                        : "hideAfter1150"
                    }`}
                  >
                    PRODUCT
                  </p>
                </button>
              </Link>
              {!isSearchActive && (
                <div
                  className="accesibilitiesIcons"
                  ref={headSearchRef}
                  onClick={handleSearchButtonClick}
                >
                  <button
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    htmlFor="top"
                    title={`Search`}
                    onClick={() => setIsSearchActive(true)}
                  >
                    <img
                      style={{ width: "27px" }}
                      src={SearchIcon}
                      alt="Shopup Admin"
                    />
                  </button>
                </div>
              )}
              {isSearchActive && (
                <div className="headSearch" ref={headSearchRef}>
                  <div className="headSearchInput">
                    <img src={SearchImg} alt="Shopup Admin" />
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div
                    className={`searchDropdownBox ${
                      isSearchActive && searchResults.length > 0 ? "" : "d-none"
                    }`}
                  >
                    <ul>
                      {searchResults.map((result, index) => (
                        <li
                          key={index}
                          onClick={() => handleSearchResultClick(result)}
                        >
                          {result.master_name}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </span>
            <ul className="navbar-nav">
              <li className="nav-item">
                <div className="timeANdDateHead">
                  <p className="dateHeadb">{inputdateformateChangeyear("")}</p>
                  <p>
                    <span className="hours">0</span>
                    <span className="minutes">0</span>
                  </p>
                </div>
              </li>
              <Link
                rel="noopener"
                onClick={() => handleLinkClick("/Manuals")}
              >
                <li className="nav-item">
                  <div className="accesibilities">
                    <button
                      className={` rsrvHeadBtn ${
                        location.pathname === "/Manuals"
                          ? "selectedHeadBtn"
                          : ""
                      } `}
                    >
                      <p>HELP</p>
                    </button>
                  </div>
                </li>
              </Link>
              <li className="nav-item">
                <span className="profileLink nav-link active paddRight0">
                  <img
                    style={{ borderRadius: "10px" }}
                    src={admin_image}
                    onError={(e) => (e.target.src = Dummy)}
                    alt="Shopup Admin"
                  />
                  <div className="dropdown profileDropdown">
                    <div
                      className="profileBtnToggle dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span>
                        <h6>{designation_name}</h6>
                        <span>{restaurant_name}</span>
                      </span>
                      <img
                        className="togglePBTN"
                        src={DownArrow}
                        alt="Shopup Admin"
                      />
                    </div>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          onClick={() => openModal()}
                        >
                          <div className="logOuticn">
                            <img
                              className="logOutimg"
                              src={logicn}
                              alt="img"
                            ></img>
                            Logout
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </span>
              </li>
              <li className="nav-item">
                <div className="saparatorDiv"></div>
              </li>
              <Link
                rel="noopener"
                onClick={() => handleLinkClick("/view_reports")}
              >
                <li className="nav-item">
                  <div className="accesibilities">
                    <div className="accesibilitiesIcons">
                      <button
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        htmlFor="top"
                        title={`Analytics`}
                        className={` ${
                          location.pathname === "/view_reports"
                            ? "selectedHeadBtn"
                            : ""
                        } `}
                      >
                        <img
                          src={` ${
                            location.pathname === "/view_reports"
                              ? AnalyticsWhite
                              : Analytics
                          } `}
                        />
                      </button>
                    </div>
                  </div>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </nav>
      <Modal show={showModal} onHide={closeModal} centered backdrop="static">
        <Modal.Body className="modal_body">
          <div className="success_img d-flex justify-content-center">
            {/* ... Modal content goes here ... */}
          </div>

          <p>Are you sure you want to Logout?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="logoutYesBtn" onClick={logoutpopup}>
            Yes
          </Button>
          <Button className="logoutNoBtn" onClick={closeModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />

      <div ref={sideBarRef}>
        <SideBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </div>
    </div>
  );
}

export default Header;
